import Layout from "../components/Layout";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from 'axios';
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../components/Loader";

export default function ShopBrand(props) {
    const [productsSettings, setProductSettings] = useState();
    const [brands, setBrands] = useState();
    const [hasMore, setHasMore] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);

    const getData = () => {
        axios.get('brands?page=' + currentPage).then(response => {
            let oldBrands = brands ? [...brands] : [];
            setBrands([...oldBrands, ...response.data.brands.data]);
            setCurrentPage(currentPage + 1);

            setHasMore(response.data.brands.current_page !== response.data.brands.last_page);
            window.triggerScroll();
        });
        axios.post('products-settings').then(response => {
            setProductSettings(response.data);
        });
        window.triggerScroll();
    }


    useEffect(() => {
        getData();
    }, []);

    return (productsSettings && brands) ? (
        <Layout activePage="shopBrand">
            <div className="designers brands pt-lg-5 mt-lg-3">
                <div className="container py-5 px-lg-0">
                    <div className="pb-3">
                        <h1>{productsSettings[0].brands_page_title}</h1>
                    </div>
                    <InfiniteScroll
                        dataLength={brands.length}
                        next={getData}
                        hasMore={hasMore}
                        loader={(
                            <>
                                {
                                    hasMore ?
                                        <Loader />
                                        :
                                        null
                                }
                            </>
                        )}
                    >
                        <div className="row">
                            {
                                brands.map((brand, index) => (
                                    <div className="col-lg-4 col-md-4 col-sm-6 pb-5" animate="left" key={index}>
                                        <div className="designer">
                                            <Link to={"/single-brand/" + brand.slug}>
                                                <div className="ratio ratio-1x1 designer-image">
                                                    <img src={brand.featured_image_path} alt={brand.name} title={brand.name} />
                                                </div>
                                                {/* <h3 className="pt-3">{brand.name}</h3> */}
                                            </Link>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </InfiniteScroll>

                </div>
            </div>
        </Layout>
    )
        :
        <div className="fixed-top h-100 w-100 d-flex align-items-center justify-content-center">
            <Loader className="loader-home" />
        </div>
}