import Layout from "../components/Layout";
import { Link, useParams, useHistory } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { useEffect, useState, useContext } from "react";
import axios from 'axios';
import GlobalState from "../GlobalState";
import Loader from "../components/Loader";

export default function Magazine(props) {
    const { magazineCategories, headerSettings, isFirstVisit } = useContext(GlobalState);
    const [magazines, setMagazines] = useState();
    const [magazineArticles, setMagazineArticles] = useState();

    let { slug } = useParams();
    const history = useHistory();
    SwiperCore.use([Navigation]);

    useEffect(() => {
        axios.post('magazine-landing?first_visit=' + (slug ? isFirstVisit('magazine-category', slug) : isFirstVisit('magazine-landing', 'true')), {
            slug: slug
        }).then(response => {
            setMagazines(response.data)
            setMagazineArticles(response.data.articles[0]);
            window.triggerScroll();
        });
    }, [slug]);

    return (magazineArticles) ? (
        <Layout activePage="magazine" activeMagazineCategoryPage={slug}>
            <>
                <div className="magazine-section-1 pt-lg-5 mt-lg-3">
                    <div className="container py-5 pb-5 ">
                        <div animate="right">
                            <Swiper className="mySwiper"
                                loop={false}
                                slidesPerView={1.4}
                                spaceBetween={20}
                                breakpoints={{
                                    991.98: {
                                        slidesPerView: 3,
                                        allowTouchMove: false,
                                    },
                                    // when window width is >= 767.98px
                                    767.98: {
                                        slidesPerView: 2,
                                    },
                                }}>
                                {
                                    magazineArticles.main_articles.map(article => (
                                        <SwiperSlide  className="swiper-slide-magazine ">
                                            <Link to={"/single-article/" + article.slug}>
                                                <div className="magazine-section-swiper h-100 flex-column d-flex" >
                                                    <div className="ratio ratio-1x1 magazine-swiper">
                                                        <img src={article.featured_image_path} alt={article.title} title={article.title} />
                                                    </div>
                                                    <div className="section-paragraph">
                                                        <h6 className="pt-4 ">{article.title}</h6>
                                                        <p className="editor-paragraph">{article.excerpt}</p>
                                                    </div>
                                                   <div>
                                                     <Link to={"/single-article/" + article.slug} className="external-button">Read More</Link>
                                                   </div>
                                                </div>
                                            </Link>
                                        </SwiperSlide>
                                    ))
                                }
                            </Swiper>
                        </div>
                    </div>
                </div>

                <div className="magazine-items px-lg-5 px-md-3 px-sm-1">
                    <div className="container py-5 pb-md-5 pb-5">
                        <div className="row justify-content-lg-between justify-content-md-between justify-content-sm-between justify-content-start align-items-center px-lg-5" animate="left">
                            {
                                magazineCategories.map(category => (
                                    <div className="col-auto pb-lg-0 pb-2 " key={category.id}>
                                        <p className={"mb-lg-0 mb-md-0 mb-0 " + (slug === category.slug ? 'external-button-list' : '')} onClick={() => history.push('/magazine/' + category.slug, { noScrollTop: true })}>{category.title}</p>
                                    </div>
                                ))
                            }
                            <div className="col-auto pb-lg-0 pb-2">
                                <Link to="/horoscope-magazine" className="mb-0 ">{headerSettings.horoscopes_menu_label}</Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="py-lg-5 pb-5">
                    <div className="position-relative">
                        <div className="container ">
                            <div className="row">
                                <div className="col-lg-8" animate="left">
                                    <Link to={"/single-article/" + magazineArticles?.highlighted_article?.slug}>
                                        <div className="ratio magazine-ratio">
                                            <img src={magazineArticles?.highlighted_article?.featured_image_path} alt={magazineArticles?.highlighted_article?.title} title={magazineArticles?.highlighted_article?.title} />
                                        </div>
                                    </Link>
                                </div>
                                <div className="position-relative">
                                    <div className="container">
                                        <div className="col-lg-6">
                                            <Link to={"/single-article/" + magazineArticles?.highlighted_article?.slug}>
                                                <div className="white-background">
                                                    <h1 className="mb-4 mt-lg-5" animate="right">{magazineArticles?.highlighted_article?.title}</h1>
                                                    <p className="mb-4" animate="right">{magazineArticles?.highlighted_article?.excerpt}</p>
                                                    <div className="mb-lg-5">
                                                        <Link to={"/single-article/" + magazineArticles?.highlighted_article?.slug} className="external-button" animate="right">{magazines.articles[0]?.read_more_label}</Link>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container py-lg-5">
                    <div className="row">
                        {
                            magazineArticles.floating_articles.map((floatingArticle, index) => (
                                <div className="col-lg-4 col-md-4 pt-md-5" scroll-styles={index % 3 === 0 ? 0.45 : (index % 2 === 0 ? 0.15 : 0.3)}>
                                    <Link to={"/single-article/" + floatingArticle.slug}>
                                        <div className="articles">
                                            <div className="ratio custom-ratio">
                                                <img src={floatingArticle.featured_image_path} alt={floatingArticle.title} title={floatingArticle.title} />
                                            </div>
                                            <h6 className="pt-4 ">{floatingArticle.title}</h6>
                                            <p>{floatingArticle.excerpt} </p>
                                            <Link className="external-button" to={"/single-article/" + floatingArticle.slug}>{magazines.articles[0]?.read_more_label}</Link>
                                        </div>
                                    </Link>
                                </div>
                            ))
                        }
                    </div>
                </div>

                <div className="py-5">
                    <div className="container">
                        <div className="row justify-content-end">

                            <div className="col-lg-8" animate="right">
                                <Link to={"/single-article/" + magazineArticles?.highlighted_article_end?.slug}>
                                    <div className="ratio magazine-ratio">
                                        <img src={magazineArticles?.highlighted_article_end?.featured_image_path} alt={magazineArticles?.highlighted_article_end?.title} title={magazineArticles?.highlighted_article_end?.title} />
                                    </div>
                                </Link>
                            </div>
                            <div className="position-relative">
                                <div className="container">
                                    <div className="col-lg-6">
                                        <Link to={"/single-article/" + magazineArticles?.highlighted_article_end?.slug}>
                                            <div className="white-bg">
                                                <h1 className="mb-4 mt-lg-5" animate="left">{magazineArticles?.highlighted_article_end?.title}</h1>
                                                <p className="mb-4" animate="left">{magazineArticles?.highlighted_article_end?.excerpt}</p>
                                                <div className="mb-lg-5">
                                                    <Link to={"/single-article/" + magazineArticles?.highlighted_article_end?.slug} className="external-button" animate="left">{magazines.articles[0]?.read_more_label}</Link>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            magazineArticles.slug && (
                                <div className="container">
                                    <div className="text-center pt-5">
                                        <Link to={"/archive-magazine/" + magazineArticles.slug} className="external-button" animate="up">ARCHIVE</Link>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </>
        </Layout>
    )
        :
        <div className="fixed-top h-100 w-100 d-flex align-items-center justify-content-center">
            <Loader className="loader-home" />
        </div>
}