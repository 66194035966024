import Layout from "../components/Layout";
import { Link, useParams } from "react-router-dom";
import { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import axios from "axios";
import { useState, useContext } from "react";
import Loader from "../components/Loader";
import GlobalState from "../GlobalState";

SwiperCore.use([Navigation]);
export default function SearchPage(props) {
    const { headerSettings } = useContext(GlobalState);
    const [productsSettings, setProductSettings] = useState();
    const [searchPage, setSearchPage] = useState();
    let { query } = useParams();
    useEffect(() => {
        axios.post('search', {
            searchQuery: query
        }).then(response => {
            setSearchPage(response.data)
            console.log(response.data)
            window.triggerScroll();
        });
        axios.post('products-settings').then(response => {
            setProductSettings(response.data);
        });
    }, [query]);


    return (searchPage) ? (
        <Layout>
            <div className="container container-search-result">
                <div className="pb-4">
                    <h3 className="mb-3" animate="left">Searching for "{query}"</h3>
                </div>
            </div>
            <>
                {
                    <>
                        {

                            <div className="single-brand-section">
                                <div className="container pt-lg-3 pb-5">
                                    <h2 className="mb-3" animate="left">{headerSettings?.featured_magazine_articles[0]?.featured_products_title}</h2>
                                    <div className="row">
                                        {

                                            searchPage.products.length === 0 ?
                                                <div>No Results</div>
                                                :
                                                searchPage.products.map(product => (

                                                    <div className="col-lg-4 col-md-4 col-sm-6 col-12 pb-5" animate="left" key={product.id}>
                                                        <Link to={"/single-product/" + product.slug}>
                                                            <div className="single-product position-relative">
                                                                <div className="ratio single-brand-img">
                                                                    <img src={product.featured_image_path} alt={product.name} title={product.name} />
                                                                </div>
                                                                <svg className="wishlist-brand" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 422.4 375.8" xmlSpace="preserve">
                                                                    <path className="wishlist-path" d="M211.2,367.8c-5.8,0-11.3-2.1-15.6-5.9c-16.3-14.3-32.1-27.7-46-39.6l-0.1-0.1c-40.8-34.7-75.9-64.7-100.4-94.3  c-27.4-33-40.1-64.3-40.1-98.6c0-33.2,11.4-63.9,32.1-86.3C62,20.5,90.7,8,121.9,8c23.4,0,44.7,7.4,63.6,21.9  c9.5,7.3,18.1,16.3,25.7,26.8c7.6-10.5,16.2-19.5,25.7-26.8C255.7,15.3,277.1,8,300.5,8c31.2,0,60,12.5,80.9,35.2  c20.7,22.4,32.1,53.1,32.1,86.3c0,34.2-12.7,65.5-40.1,98.6c-24.5,29.5-59.7,59.5-100.4,94.3c-13.9,11.9-29.7,25.3-46.1,39.6  C222.5,365.8,217,367.8,211.2,367.8L211.2,367.8z" />
                                                                </svg>
                                                                <p className="title my-2">{product.name}</p>
                                                                <p>{product.price}</p>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                ))
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                        {


                            <div className="magazine-section-1">
                                <div className="container pb-5 ">
                                    <h2 className="mb-3">{headerSettings.magazine_menu_label}</h2>
                                    <div animate="right">
                                        <Swiper className="mySwiper"
                                            loop={false}
                                            slidesPerView={1.4}
                                            spaceBetween={20}
                                            breakpoints={{

                                                991.98: {
                                                    slidesPerView: 3,
                                                    allowTouchMove: false,
                                                },
                                                // when window width is >= 767.98px
                                                767.98: {
                                                    slidesPerView: 2,
                                                },
                                            }}>
                                            {
                                                searchPage ?
                                                    searchPage.magazine.length === 0 ?
                                                        <div>No Results</div> :
                                                        searchPage.magazine.map(article => (
                                                            <SwiperSlide key={article.id}>
                                                                <Link to={"/single-article/" + article.slug}>
                                                                    <div className="magazine-section-swiper" >
                                                                        <div className="ratio ratio-1x1 magazine-swiper">
                                                                            <img src={article.featured_image_path} alt={article.title} title={article.title} />
                                                                        </div>
                                                                        <h6 className="pt-4 pb-2">{article.title}</h6>
                                                                        <p className="editor-paragraph">{article.excerpt}</p>
                                                                        <Link to="/single-article" className="external-button">read</Link>
                                                                    </div>
                                                                </Link>
                                                            </SwiperSlide>
                                                        ))
                                                    :
                                                    null
                                            }
                                        </Swiper>


                                    </div>
                                </div>
                            </div>
                        }

                        {

                            <div className="magazine-section-1">
                                <div className="container pb-5 ">
                                    <h2 className="mb-3">{productsSettings[0].brands_page_title}</h2>
                                    <div animate="right">
                                        <div className="row">
                                            {
                                                searchPage.brands.length === 0 ?
                                                    <div>No Results</div> :
                                                    searchPage.brands.map(brand => (
                                                        <div className="col-lg-4 col-md-4 col-sm-6 pb-5" animate="left" key={brand.id}>
                                                            <div className="designer">
                                                                <Link to={"/single-brand/" + brand.slug}>
                                                                    <div className="ratio ratio-1x1 designer-image">
                                                                        <img src={brand.featured_image_path} alt={brand.name} title={brand.name} />
                                                                    </div>
                                                                    <h3 className="pt-3">{brand.name}</h3>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    ))
                                            }


                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {

                            <div className="magazine-section-1">
                                <div className="container pb-5 ">
                                    <h2 className="mb-3">{headerSettings.horoscopes_menu_label}</h2>
                                    <div className="row justify-content-center">
                                        {
                                            searchPage.horoscopes.length === 0 ?
                                                <div>No Results</div>
                                                :
                                                searchPage.horoscopes.map(horoscope => (
                                                    <div className="col-lg-4 col-md-4 col-sm-6 col-12 py-5 add-padding" key={horoscope.id}>
                                                        <Link to={"/single-horoscope/" + horoscope.slug + "/" + horoscope.latest_article.date}>
                                                            <div className="ratio ratio-1x1">
                                                                <img src={horoscope.icon_path} alt={horoscope.name} title={horoscope.name} />
                                                            </div>
                                                            <h5 className="text-center mt-5">{horoscope.name}</h5>
                                                        </Link>
                                                    </div>
                                                ))
                                        }
                                        {/* {
                                                        searchPage.horoscopes.length == 0 && (
                                                            null
                                                        )
                                                    } */}

                                    </div>
                                </div>

                            </div>

                        }
                    </>
                }
            </>

        </Layout >
    )
        :
        <div className="fixed-top h-100 w-100 d-flex align-items-center justify-content-center">
            <Loader className="loader-home" />
        </div>
}