import { Link, useHistory } from "react-router-dom";
import React, { useEffect, useState, useContext, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import GlobalState from "../GlobalState";
import Loader from "./Loader";
import axios from "axios";

export default function Layout(props) {
    SwiperCore.use([Navigation]);

    const { headerSettings, footerSettings, socialChannels, brandsHeader, productsCategories, magazineCategories, wishlistItems, wishlistItemsDetails, informativePages, wishlistOpen, setWishlistOpen, acceptedCookies, setAcceptedCookies } = useContext(GlobalState);
    const history = useHistory();
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [shopBrandOpen, setShopBrandOpen] = useState(false);
    const [shopByCategoryOpen, setShopByCategoryOpen] = useState(false);
    const [magazineOpen, setMagazineOpen] = useState(false);
    const [activeAlphabetLetter, setActiveAlphabetLetter] = useState('');
    const [mobileSearchOpen, setMobileSearchOpen] = useState(false);
    const [searchDesktop, setSearchDesktop] = useState('');
    const [searchMobile, setSearchMobile] = useState();
    const [productsSettings, setProductsSettings] = useState();
    const wishlistRef = useRef(null);

    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (wishlistRef.current && !wishlistRef.current.contains(event.target)) {
                setWishlistOpen(false)
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wishlistRef, setWishlistOpen]);

    function wishlistClick() {
        setWishlistOpen(!wishlistOpen);
    }

    function menuClick() {
        setMobileMenuOpen(true);
    }

    function menuClose() {
        setMobileMenuOpen(false);
        setShopBrandOpen(false);
        setShopByCategoryOpen(false);
        setMagazineOpen(false);
    }
    function categoryClick() {
        menuClose();
    }

    function subCategoryClick() {
        menuClose();
    }
    function brandName() {
        menuClose();
    }

    function shopBrand() {
        setShopBrandOpen(true);
    }

    function shopByCategory() {
        setShopByCategoryOpen(true);
    }

    function magazine() {
        setMagazineOpen(true);
    }

    function mobileSearch() {
        setMobileSearchOpen(true);
    }

    function homeLink() {
        menuClose();
        history.push('/');
    }

    function searchSubmitDesktop(e) {
        e.preventDefault();
        console.log('searchDesktop', searchDesktop);
        history.push('/search/' + searchDesktop);
    }

    function searchSubmitMobile(e) {
        e.preventDefault();
        history.push('/search/' + searchMobile);
    }

    function acceptCookies() {
        localStorage.setItem('cookies-accepted', 1);
        setAcceptedCookies(true);
    }

    useEffect(() => {
        axios.post('products-settings').then(response => {
            setProductsSettings(response.data);
        });
    }, []);

    useEffect(() => {
        document.querySelector('body').style.overflow = mobileMenuOpen ? 'hidden' : null;
        document.querySelector('html').style.overflow = mobileMenuOpen ? 'hidden' : null;
    }, [mobileMenuOpen]);

    useEffect(() => {
        if (!localStorage.getItem('cookies-accepted')) setAcceptedCookies(false);
    }, [setAcceptedCookies]);

    return (
        <>
            <div className="custom-navbar" >
                <div className="container px-3 px-lg-0 px-md-0 px-sm-0">
                    <div className="row ">
                        <div className="col d-flex justify-content-start justify-content-lg-end third-dropdown order-lg-3">
                            <div className="custom-dropdown d-none d-lg-block">
                                <div className="menu-link h-100 d-flex align-items-center">
                                    <Link className={(props.activePage === "magazine" ? "ms-xl-5 nav-item active-link" : "ms-xl-5 nav-item")} to={"/magazine"}>{headerSettings.magazine_menu_label}</Link>
                                </div>
                                <div className="dropdown-content shadow pb-3">
                                    <hr className="mt-0 mb-5" />
                                    <div className="container px-0">
                                        <div className="row">
                                            <div className="col">
                                                <div className="dropdown-items">
                                                    {
                                                        magazineCategories.map(category => (
                                                            <Link to={"/magazine/" + category.slug} className={props.activeMagazineCategoryPage === category.slug ? " horoscope-item active-link " : "horoscope-item "} key={category.id}>{category.title}</Link>
                                                        ))
                                                    }
                                                    <Link to={"/horoscope-magazine"} className={(props.activeHoroscopePage === "horoscope" ? "horoscope-item active-link" : "horoscope-item")} >{headerSettings.horoscopes_menu_label}</Link>
                                                </div>
                                            </div>
                                            {
                                                headerSettings ?
                                                    headerSettings.featured_magazine_articles.map(featuredMagazine => (
                                                        <div className="col" key={featuredMagazine.id}>
                                                            <Link className="nav-item" to={"/single-article/" + featuredMagazine.slug}>
                                                                <div className="magazine-section">
                                                                    <div className="ratio dropdown-image">
                                                                        <img src={featuredMagazine.featured_image_path} alt="magazine" />
                                                                    </div>
                                                                    <h4 className="title-image mt-4">{featuredMagazine.title}</h4>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    ))
                                                    :
                                                    <div>Loading</div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="menu-space d-none d-lg-block"></div>
                            <div className="custom-dropdown d-none d-lg-block">
                                <div className="h-100 d-flex align-items-center menu-link">
                                    <Link to="/sell-with-us" className={(props.activePage === "sellWithUs" ? " nav-item active-link" : "nav-item")}>{headerSettings.sell_with_us_menu_label}</Link>
                                </div>
                            </div>
                            <div className="menu-space d-none d-lg-block"></div>
                            {/* <div className="custom-dropdown" onClick={wishlistClick}> */}
                            <div className="" onClick={wishlistClick} ref={wishlistRef}>
                                <div className="menu-link h-100 d-flex align-items-center">
                                    {/* <Link to="/wishlist"> */}
                                    <svg className="wishlist-svg" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 422.4 375.8" xmlSpace="preserve">
                                        <path className="wishlist-path" d="M211.2,367.8c-5.8,0-11.3-2.1-15.6-5.9c-16.3-14.3-32.1-27.7-46-39.6l-0.1-0.1c-40.8-34.7-75.9-64.7-100.4-94.3  c-27.4-33-40.1-64.3-40.1-98.6c0-33.2,11.4-63.9,32.1-86.3C62,20.5,90.7,8,121.9,8c23.4,0,44.7,7.4,63.6,21.9  c9.5,7.3,18.1,16.3,25.7,26.8c7.6-10.5,16.2-19.5,25.7-26.8C255.7,15.3,277.1,8,300.5,8c31.2,0,60,12.5,80.9,35.2  c20.7,22.4,32.1,53.1,32.1,86.3c0,34.2-12.7,65.5-40.1,98.6c-24.5,29.5-59.7,59.5-100.4,94.3c-13.9,11.9-29.7,25.3-46.1,39.6  C222.5,365.8,217,367.8,211.2,367.8L211.2,367.8z" />
                                    </svg>
                                    {/* </Link> */}
                                </div>
                                {/* <div className="dropdown-content wishlist-dropdown"> */}
                                <div className="dropdown-wishlist wishlist-dropdown">
                                    <div className="container">
                                        <div className="position-relative">
                                            <div className={"shadow wishlist" + (wishlistOpen ? " show" : "")}>
                                                <hr className="mt-0 mb-5" />
                                                <div className="container background-wishlist">
                                                    <h3>{headerSettings.whishlist_title}</h3>
                                                    <hr />
                                                    {
                                                        // wishlistItemsDetailsLoading ?
                                                        //     <Loader />
                                                        //     :
                                                        wishlistItems.length > 0 ?
                                                            <>
                                                                {
                                                                    wishlistItemsDetails ?
                                                                        wishlistItemsDetails.map((wishlistItemDetails, index) => (
                                                                            index < 3 &&
                                                                            <React.Fragment key={wishlistItemDetails.id}>
                                                                                <div className="row">
                                                                                    <div className="col">
                                                                                        <Link to={"/single-product/" + wishlistItemDetails.slug}>
                                                                                            <div className="ratio wishlist-image">
                                                                                                <img src={wishlistItemDetails.featured_image_path} alt="wishlist" />
                                                                                            </div>
                                                                                        </Link>
                                                                                    </div>
                                                                                    <div className="col-3 align-self-end gx-0">
                                                                                        <Link to={"/single-product/" + wishlistItemDetails.slug}>
                                                                                            <h4>{wishlistItemDetails.name}</h4>
                                                                                            <h6 className="mb-0">{wishlistItemDetails.price}</h6>
                                                                                        </Link>
                                                                                    </div>
                                                                                    <div className="col-auto align-self-end">
                                                                                        <a href={wishlistItemDetails.buy_url} target="_blank" rel="noreferrer" className="button black-button">shop</a>
                                                                                    </div>
                                                                                </div>
                                                                                <hr />
                                                                            </React.Fragment>
                                                                        ))
                                                                        :
                                                                        null
                                                                }
                                                                <div className="text-center pt-2 pb-5">
                                                                    <Link to="/wishlist" className="button wishlist-button">{headerSettings.whishlist_button_label}</Link>
                                                                </div>
                                                            </>
                                                            :
                                                            <p>EMPTY WISHLIST</p>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="menu-space"></div>

                            <div className="custom-search" onClick={mobileSearch}>
                                <div className="h-100 d-flex align-items-center" >
                                    <img className="icon" src="/assets/images/search.svg" alt="search" />
                                </div>
                                <div className="search-section shadow">
                                    <form onSubmit={searchSubmitMobile} className="search d-flex">
                                        <input placeholder={headerSettings.search_placeholder} value={searchMobile} onChange={e => setSearchMobile(e.target.value)} />
                                        <button disabled={!searchMobile} type="submit" className="search-img">
                                            {/* <Link to="/search"> */}
                                            <img src="/assets/images/search.svg" alt="search" onChange={e => setSearchMobile(e.target.value)} />
                                            {/* </Link> */}
                                        </button>
                                    </form>
                                </div>
                            </div>

                            <div className={"search-mobile d-block d-lg-none shadow align-items-center d-flex" + (mobileSearchOpen ? " show" : "")} >
                                <form onSubmit={searchSubmitDesktop} className="container search d-flex">
                                    <button disabled={!searchDesktop} type="submit" className="search-img">
                                        {/* <Link to="/search"> */}
                                        <img src="/assets/images/search.svg" alt="search" />
                                        {/* </Link> */}
                                    </button>
                                    <input type="text" placeholder={headerSettings.search_placeholder} value={searchDesktop} onChange={e => setSearchDesktop(e.target.value)} />
                                    <div className="position-relative">
                                        <div className="close-search" onClick={() => setMobileSearchOpen(false)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 12.338 12.338">
                                                <line id="Line_35" data-name="Line 35" y2="13.448" transform="translate(10.924 1.414) rotate(45)" fill="none" stroke="#000000" strokeLinecap="round" strokeWidth="1.5"></line>
                                                <line id="Line_36" data-name="Line 36" y2="13.448" transform="translate(1.414 1.414) rotate(-45)" fill="none" stroke="#000000" strokeLinecap="round" strokeWidth="1.5"></line>
                                            </svg>
                                        </div>
                                    </div>
                                </form>

                            </div>

                        </div>
                        <div className="col-auto py-4 order-lg-2">
                            <Link to="/">
                                <img className="logo-header" src="/assets/images/logo-stilo.png" alt="logo" />
                            </Link>
                        </div>
                        <div className="col d-flex justify-content-end justify-content-lg-start order-lg-1">
                            <div className="custom-dropdown d-none d-lg-block">
                                <div className="menu-link h-100 d-flex align-items-center">
                                    <Link to="/shop-brands" className={(props.activePage === "shopBrand" ? " nav-item active-link" : " nav-item")}>{headerSettings.brands_menu_label}</Link>
                                </div>
                                <div className="dropdown-content shadow">
                                    <hr className="m-0" />
                                    <div className="container shopbrand px-0 py-5 ">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="mb-4 text-center">
                                                    <h1>{headerSettings.brand_submenu_title}</h1>
                                                </div>

                                                <div className="row justify-content-between">
                                                    {
                                                        Object.keys(brandsHeader.brands).map((letter, index) => (
                                                            <div className={"col-auto " + (activeAlphabetLetter === letter ? 'active-letter' : '')} onClick={() => setActiveAlphabetLetter(activeAlphabetLetter === letter ? '' : letter)} key={index}>
                                                                <p className="mb-0" key={letter}>{letter}</p>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                                {
                                                    activeAlphabetLetter && (
                                                        <div className="d-flex pt-4">
                                                            {
                                                                brandsHeader.brands[activeAlphabetLetter].map((brand, index) => (
                                                                    <p className="header-brand" key={index}><Link to={"/single-brand/" + brand.slug}>{brand.name}</Link></p>
                                                                ))
                                                            }
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="menu-space"></div>
                            <div className="custom-dropdown d-none d-lg-block">
                                <div className="menu-link h-100 d-flex align-items-center">
                                    <p className={(props.activePage === "shopByCategory" ? "nav-item active-link nav-item mb-0" : "nav-item nav-item mb-0")}>{headerSettings.category_menu_label}</p>
                                </div>
                                <div className="dropdown-content second-dropdown shadow">
                                    <hr className="mt-0 mb-0" />
                                    <div className="container py-4 px-0">
                                        <div className="row justify-content-between">
                                            <div className="col submenu-items">
                                                <div className="inside-dropdown">
                                                    <div className="pb-2 dropdown-item-hover d-flex align-items-center">
                                                        {
                                                            productsSettings ?
                                                                <Link className={`just-landed-menu ${ props.activeCategoryPage == 'just-landed' ? 'category underline me-3':  ''}`} to="/just-landed">{productsSettings[0].just_landed_title}</Link>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                productsCategories.map(category => (
                                                    <div className="col submenu-items" key={category.id}>
                                                        <div className="inside-dropdown">
                                                            <div className="pb-2 dropdown-item-hover d-flex align-items-center">
                                                                <Link to={"/category/" + category.slug} className={(props.activeCategoryPage === category.slug ? "category underline me-3" : "category me-3")}>{category.title}</Link>
                                                                {
                                                                    category.subcategories.length > 0 && (
                                                                        <img className=" arrow" src="/assets/images/dropdown-arrow.svg" alt="arrow" />
                                                                    )
                                                                }
                                                            </div>
                                                            {
                                                                category.subcategories.length > 0 && (
                                                                    <div className="inside-dropdown-content">
                                                                        {
                                                                            category.subcategories.map(subCategory => (
                                                                                <Link to={"/category/" + category.slug + "/" + subCategory.slug} className={props.activeSubcategoryPage === subCategory.slug ? "subcategory active-subcategory py-2" : "subcategory py-2"} key={subCategory.id}>{subCategory.title}</Link>
                                                                            ))
                                                                        }
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="h-100 align-items-center d-flex d-lg-none" onClick={menuClick}>
                                <div className="burger-menu">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="16" viewBox="0 0 24 16">
                                        <g id="fi-rr-menu-burger" transform="translate(0 -4)">
                                            <rect id="Rectangle_118" data-name="Rectangle 118" width="24" height="2" rx="1" transform="translate(0 11)" />
                                            <rect id="Rectangle_119" data-name="Rectangle 119" width="24" height="2" rx="1" transform="translate(0 4)" />
                                            <rect id="Rectangle_120" data-name="Rectangle 120" width="24" height="2" rx="1" transform="translate(0 18)" />
                                        </g>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={"mobile-menu" + (mobileMenuOpen ? "" : " hide")}>
                <div className="container py-4 px-3 px-lg-0 px-md-0 px-sm-0">
                    <div className="row align-items-center">
                        <div className="col"></div>
                        <div className="col-auto">
                            <Link to="/" onClick={homeLink}>
                                <img className="logo-header" src="/assets/images/logo-stilo.png" alt="logo" />
                            </Link>
                        </div>
                        <div className="col d-flex justify-content-end ">
                            <div className="close-mobile-menu" onClick={menuClose}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 12.338 12.338">
                                    <line id="Line_35" data-name="Line 35" y2="13.448" transform="translate(10.924 1.414) rotate(45)" fill="none" stroke="#000000" strokeLinecap="round" strokeWidth="1.5"></line>
                                    <line id="Line_36" data-name="Line 36" y2="13.448" transform="translate(1.414 1.414) rotate(-45)" fill="none" stroke="#000000" strokeLinecap="round" strokeWidth="1.5"></line>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container py-4 px-3 px-lg-0 px-md-0 px-sm-0">
                    <div className="mobile-menu-items">
                        <div className="d-flex align-items-center justify-content-center text-center mb-4" onClick={shopBrand}>
                            <p className="d-block mb-0 me-3">{headerSettings.brands_menu_label}</p>
                            <img src="/assets/images/arrow-next.svg" alt="next" />

                        </div>

                        <div className="d-flex align-items-center justify-content-center text-center mb-4" onClick={shopByCategory}>
                            <p className="d-block mb-0 me-3" >{headerSettings.category_menu_label}</p>
                            <img src="/assets/images/arrow-next.svg" alt="next" />
                        </div>

                        <div className="d-flex align-items-center justify-content-center text-center mb-4" onClick={magazine}>
                            <p className="d-block  mb-0 me-3">{headerSettings.magazine_menu_label}</p>
                            <img src="/assets/images/arrow-next.svg" alt="next" />

                        </div>

                        <div className="d-flex align-items-center justify-content-center text-center mb-4">
                            <Link to="/sell-with-us" className="d-block  mb-0">{headerSettings.sell_with_us_menu_label}</Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className={"shop-brand" + (shopBrandOpen ? "" : " hide")}>
                <div className="container py-4 px-3 px-lg-0 px-md-0 px-sm-0">
                    <div className="row align-items-center">
                        <div className="col"></div>
                        <div className="col-auto">
                            <Link to="/" onClick={homeLink}>
                                <img className="logo-header" src="/assets/images/logo-stilo.png" alt="logo" />
                            </Link>
                        </div>
                        <div className="col d-flex justify-content-end">
                            <div className="close-mobile-menu" onClick={menuClose}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 12.338 12.338">
                                    <line id="Line_35" data-name="Line 35" y2="13.448" transform="translate(10.924 1.414) rotate(45)" fill="none" stroke="#000000" strokeLinecap="round" strokeWidth="1.5"></line>
                                    <line id="Line_36" data-name="Line 36" y2="13.448" transform="translate(1.414 1.414) rotate(-45)" fill="none" stroke="#000000" strokeLinecap="round" strokeWidth="1.5"></line>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container py-4">
                    <div className="d-flex align-items-center mobile-header" onClick={() => setShopBrandOpen(false)}>
                        <img className="back-arrow" src="/assets/images/back-arrow.svg" alt="back" />
                        <h3 className="mb-0 ms-3">{headerSettings.brand_submenu_title}</h3>
                    </div>
                </div>
                <div className="container py-4">
                    <div className="row align-items-center justify-content-between flex-nowrap letters ">
                        {
                            Object.keys(brandsHeader.brands).map((letter, index) => (
                                <div className="col-auto " onClick={() => setActiveAlphabetLetter(letter)} key={index}>
                                    <p>{letter}</p>
                                </div>
                            ))
                        }
                    </div>

                    <div className="row pt-4">
                        <div className="text-center mb-4">
                            <Link to="/shop-brands">ALL BRANDS</Link>
                        </div>
                        {
                            activeAlphabetLetter && (
                                brandsHeader.brands[activeAlphabetLetter].map((brand, index) => (
                                    <div className="text-center mb-4" key={index}>
                                        <Link to={"/single-brand/" + brand.slug} onClick={brandName}>{brand.name}</Link>
                                    </div>
                                ))
                            )
                        }
                    </div>
                </div>


            </div>

            <div className={"shop-category" + (shopByCategoryOpen ? "" : " hide")}>
                <div className="container py-4 px-3 px-lg-0 px-md-0 px-sm-0">
                    <div className="row align-items-center">
                        <div className="col"></div>
                        <div className="col-auto">
                            <Link to="/" onClick={homeLink}>
                                <img className="logo-header" src="/assets/images/logo-stilo.png" alt="logo" />
                            </Link>
                        </div>
                        <div className="col d-flex justify-content-end" >
                            <div className="close-mobile-menu" onClick={menuClose}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 12.338 12.338">
                                    <line id="Line_35" data-name="Line 35" y2="13.448" transform="translate(10.924 1.414) rotate(45)" fill="none" stroke="#000000" strokeLinecap="round" strokeWidth="1.5"></line>
                                    <line id="Line_36" data-name="Line 36" y2="13.448" transform="translate(1.414 1.414) rotate(-45)" fill="none" stroke="#000000" strokeLinecap="round" strokeWidth="1.5"></line>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container py-4">
                    <div className="d-flex align-items-center mobile-header" onClick={() => setShopByCategoryOpen(false)}>
                        <img className="back-arrow" src="/assets/images/back-arrow.svg" alt="back" />
                        <h3 className="mb-0 ms-3">{headerSettings.category_menu_label}</h3>
                    </div>
                </div>
                <div className="container py-4">
                    {
                        productsSettings ?
                            <div className=" text-center mb-2">
                                <p><Link className="dropdown-title" to="/just-landed">{productsSettings[0].just_landed_title}</Link></p>
                            </div>
                            :
                            null
                    }
                    {
                        productsCategories.map(category => (
                            <div className=" text-center mb-2" key={category.id}>
                                <p><Link className="dropdown-title" to={"/category/" + category.slug} onClick={categoryClick}>{category.title}</Link></p>
                                {
                                    category.subcategories.map(subCategory => (
                                        <div className="text-center" key={subCategory.id}>
                                            <p> <Link to={"/category/" + category.slug + "/" + subCategory.slug} key={subCategory.id} onClick={subCategoryClick} className={props.activeSubcategoryPage === subCategory.slug ? 'text-decoration-underline' : ''}>{subCategory.title}</Link></p>
                                        </div>
                                    ))
                                }
                            </div>
                        ))
                    }
                </div>
            </div>

            <div className={"magazine" + (magazineOpen ? "" : " hide")}>
                <div className="container py-4 px-3 px-lg-0 px-md-0 px-sm-0">
                    <div className="row align-items-center">
                        <div className="col"></div>
                        <div className="col-auto">
                            <Link to="/" onClick={homeLink}>
                                <img className="logo-header" src="/assets/images/logo-stilo.png" alt="logo" />
                            </Link>
                        </div>
                        <div className="col d-flex justify-content-end" >
                            <div className="close-mobile-menu" onClick={menuClose}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 12.338 12.338">
                                    <line id="Line_35" data-name="Line 35" y2="13.448" transform="translate(10.924 1.414) rotate(45)" fill="none" stroke="#000000" strokeLinecap="round" strokeWidth="1.5"></line>
                                    <line id="Line_36" data-name="Line 36" y2="13.448" transform="translate(1.414 1.414) rotate(-45)" fill="none" stroke="#000000" strokeLinecap="round" strokeWidth="1.5"></line>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container py-4">
                    <div className="d-flex align-items-center mobile-header" onClick={() => setMagazineOpen(false)}>
                        <img className="back-arrow" src="/assets/images/back-arrow.svg" alt="back" />
                        <h3 className="mb-0 ms-3">{headerSettings.magazine_menu_label}</h3>
                    </div>
                </div>
                <div className="container py-4">
                    <div className="text-center mb-4">
                        <Link to="/magazine">{headerSettings.magazine_menu_label}</Link>
                    </div>
                    {
                        magazineCategories.map(category => (
                            <div className="text-center mb-4" key={category.id}>
                                <Link to={"/magazine/" + category.slug}>{category.title}</Link>
                            </div>
                        ))
                    }
                    <div className="text-center mb-4">
                        <Link to="/horoscope-magazine">{headerSettings.horoscopes_menu_label}</Link>
                    </div>
                </div>
                <div className="position-relative">
                    <div className="container magazine-mobile">
                        <div className="position-relative px-5">
                            {
                                magazineOpen && (
                                    <>
                                        <Swiper loop={true} slidesPerView={1} spaceBetween={30} centeredSlides={true} navigation={{
                                            nextEl: '.swiper-button-next',
                                            prevEl: '.swiper-button-prev'
                                        }}
                                            breakpoints={{
                                                // when window width is >= 767.98px
                                                767.98: {
                                                    slidesPerView: 2,
                                                },

                                                575.98: {
                                                    slidesPerView: 1,
                                                },
                                            }}
                                            className="mySwiper">
                                            {
                                                headerSettings ?
                                                    headerSettings.featured_magazine_articles.map(featuredMagazine => (
                                                        <SwiperSlide>
                                                            <div className="text-center">
                                                                <Link className="nav-item" to={"/single-article/" + featuredMagazine.slug}>
                                                                    <div className="ratio dropdown-image">
                                                                        <img src={featuredMagazine.featured_image_path} alt="magazine" />
                                                                    </div>
                                                                    <h4 className="mt-4">{featuredMagazine.title}</h4>
                                                                </Link>
                                                            </div>
                                                        </SwiperSlide>
                                                    ))
                                                    :
                                                    <Loader />
                                            }

                                        </Swiper>
                                        <div className="swiper-button-prev menu-swiper">
                                            <img src="/assets/images/prev.svg" alt="prev" />
                                        </div>
                                        <div className="swiper-button-next menu-swiper">
                                            <img src="/assets/images/next.svg" alt="next" />
                                        </div>
                                    </>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>

            {props.children}

            <div className="footer">
                <div className="top-footer">
                    <div className="text-center py-4">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-4 col-md-6">
                                    <h5>{footerSettings.newsletter_title}</h5>
                                    <p>{footerSettings.newsletter_subtitle}</p>
                                    <form action="https://stilorama.us8.list-manage.com/subscribe/post?u=a9894f8f86513f85332d0302f&amp;id=15e3508f8c" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" target="_blank" className="footer-form">
                                        <div className="row ">
                                            <div className="col-lg-9 col  pe-0">
                                                <input type="email" name="EMAIL" className="email" required placeholder={footerSettings.newsletter_email_placeholder} />
                                            </div>
                                            <div className="col-lg-3 col-auto">
                                                <button className="button-submit">{footerSettings.newsletter_button_label}</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bottom-footer">
                    <div className="container">
                        <div className="row py-4">
                            <div className="col-12 text-center text-lg-start">
                                <Link to="/">
                                    <img className="footer-logo" src={footerSettings.logo_path} alt="logo" />
                                </Link>
                            </div>
                        </div>
                        <div className="footer-line mb-4 mb-lg-0 m-0" />
                        <div className="row align-items-center d-block d-lg-flex text-center text-lg-start py-lg-4 pb-0">
                            <div className="col mb-3 mb-lg-0">
                                <Link className={(props.activePage === "aboutUs" ? "footer-link active-link" : "footer-link")} to="/about-us">{footerSettings.about_menu_label}</Link>
                            </div>
                            <div className="col mb-3 mb-lg-0">
                                <Link className={(props.activePage === "contactUs" ? "footer-link active-link" : "footer-link")} to="/contact-us">{footerSettings.contact_menu_label}</Link>
                            </div>
                            <div className="col mb-3 mb-lg-0">
                                <Link className={(props.activePage === "privacyPolicy" ? "footer-link active-link" : "footer-link")} to="/privacy-policy">{footerSettings.privacy_menu_label}</Link>
                            </div>
                            <div className="col mb-3 mb-lg-0 text-center text-lg-end">
                                <Link className={(props.activePage === "termsConditions" ? "footer-link active-link" : "footer-link")} to="/terms-and-conditions">{footerSettings.terms_menu_label}</Link>
                            </div>
                            <div className="col mb-4 mb-lg-0 mt-4 mt-lg-0 d-flex order-first icons-footer">
                                {
                                    socialChannels.map((socialChannel, index) => (
                                        <a href={socialChannel.url} target="_blank" rel="noreferrer" key={index}>
                                            <img className="icon-footer me-3" src={socialChannel.icon_path} alt={socialChannel.title} />
                                        </a>
                                    ))
                                }
                            </div>
                        </div>
                        <div className="footer-line" />
                        <div className="py-4">
                            <div className="external-link footer-item text-center" dangerouslySetInnerHTML={{ __html: footerSettings.copyright }} />
                        </div>
                    </div>
                </div>
            </div>

            <div className={"cookie-popup " + (acceptedCookies ? "hidden" : "")}>
                <div className="container">
                    <div className="shadow bg-white p-4">
                        <h4 className="fw-bold mb-3">{informativePages.cookies_policy_title}</h4>
                        <div dangerouslySetInnerHTML={{ __html: informativePages.cookies_policy_content }} />
                        <span className="external-button" onClick={acceptCookies}>{informativePages.cookies_policy_button_title}</span>
                    </div>
                </div>
            </div>

        </>
    )
}