import { useEffect, useContext, useState } from "react";
import Layout from "../components/Layout";
import Select from 'react-select';
import GlobalState from "../GlobalState";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import axios from 'axios';
import { useHistory } from "react-router-dom";

export default function SellWithUsForm() {
    const { sellWithUsData } = useContext(GlobalState);
    const [startDate, setStartDate] = useState('');
    const [regionsOptions, setRegionsOptions] = useState([]);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [accountType, setAccountType] = useState('');
    const [address, setAddress] = useState('');
    const [addressTwo, setAddressTwo] = useState('');
    const [selectedRegion, setSelectedRegion] = useState();
    const [city, setCity] = useState('');
    const [postCode, setPostCode] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [websiteUrl, setWebsiteUrl] = useState('');
    const [gender, setGender] = useState('');
    const [occupation, setOccupation] = useState('');
    const [errorMessages, setErrorMessages] = useState([]);
    const [formLoading, setFormLoading] = useState('');
    const [terms, setTerms] = useState('')
    const history = useHistory();

    useEffect(() => {
        let newRegions = [];
        sellWithUsData.regions.forEach((region) => {
            newRegions.push({
                value: region.id,
                label: region.name,
            });
        });
        setRegionsOptions(newRegions);

        window.triggerScroll();
    }, [sellWithUsData.regions]);

    const contactSubmit = (e) => {
        e.preventDefault();

        if (formLoading) return;

        setFormLoading(true);

        axios.post('sell-with-us-form-submit', {
            "first_name": firstName,
            "last_name": lastName,
            "company_name": companyName,
            "account_type": accountType,
            "address": address,
            "address_line_two": addressTwo,
            "region": selectedRegion ? selectedRegion?.label : "",
            "city": city,
            "postcode": postCode,
            "phone": phone,
            "email": email,
            "website_url": websiteUrl,
            "gender": gender,
            "age": startDate,
            "occupation": occupation,
            'accept_terms': terms
        })
            .then(response => {
                setFirstName('');
                setLastName('');
                setCompanyName('');
                setAccountType('');
                setAddress('');
                setAddressTwo('');
                setCity('');
                setPostCode('');
                setPhone('');
                setEmail('');
                setWebsiteUrl('');
                setGender('');
                setStartDate('');
                setOccupation('');
                setErrorMessages([]);
                history.push("/sell-with-us-form-success");
            })
            .catch(error => {
                setErrorMessages(error.response.data.errors);
            })
            .finally(() => {
                setFormLoading(false);
            });
    }

    return (
        <Layout activePage="sellWithUs">

            <div className="container pb-5 px-lg-0 pt-lg-5 mt-lg-3">
                <div className="sell-with-us-page">
                    <div className="pb-4 pt-5" animate="up">
                        <h1 className="mb-4">{sellWithUsData.sellWithUsSettings.form_title}</h1>
                    </div>
                    <div className="row">
                        <div className="col-lg-5 registration-form" animate="down">
                            <form onSubmit={contactSubmit} className="registration">
                                <div className="pb-3">
                                    <input type="text" placeholder={sellWithUsData.sellWithUsSettings.first_name_label} value={firstName} onChange={e => setFirstName(e.target.value)} />
                                </div>
                                <div className="pb-3">
                                    <input type="text" placeholder={sellWithUsData.sellWithUsSettings.last_name_label} value={lastName} onChange={e => setLastName(e.target.value)} />
                                </div>
                                <div className="pb-3">
                                    <input type="text" placeholder={sellWithUsData.sellWithUsSettings.company_name} value={companyName} onChange={e => setCompanyName(e.target.value)} />
                                </div>
                                <div className="type-checkbox">
                                    <h2 className="pb-3 mb-0">{sellWithUsData.sellWithUsSettings.account_type_label}</h2>
                                    {
                                        sellWithUsData.accountTypes.map(accountType => (
                                            <label className="check-box me-5">
                                                <input type="radio" name="account-type" value={accountType} onChange={() => setAccountType(accountType.name)} key={accountType.name} />
                                                <span className="checkmark"></span>
                                                <h6 className="mb-0" >{accountType.name}</h6>
                                            </label>
                                        ))
                                    }
                                </div>
                                <div className="pb-3">
                                    <input type="text" placeholder={sellWithUsData.sellWithUsSettings.address_label} value={address} onChange={e => setAddress(e.target.value)} />
                                </div>
                                <div className="pb-3">
                                    <input type="text" placeholder={sellWithUsData.sellWithUsSettings.address_line_two_label} value={addressTwo} onChange={e => setAddressTwo(e.target.value)} />
                                </div>
                                <div className="pb-3">
                                    <Select
                                        classNamePrefix="custom-select"
                                        value={selectedRegion}
                                        onChange={newValue => setSelectedRegion(newValue)}
                                        options={regionsOptions}
                                        placeholder={sellWithUsData.sellWithUsSettings.region_label}
                                        styles={{
                                            menu: (provided, state) => ({
                                                ...provided,
                                                borderStyle: 'none',
                                                padding: '0px',
                                                borderRadius: '0',
                                            }),
                                            option: (provided, state) => ({
                                                color: state.isSelected ? 'black' : 'black',
                                                padding: '5px 20px 5px',
                                                fontSize: '12px',
                                                zIndex: '2000',
                                                borderStyle: 'none',
                                                textTransform: 'capitalize',
                                                cursor: "pointer",
                                                borderRadius: '0',
                                            }),
                                            control: (provided, state) => ({
                                                ...provided,
                                                borderBottom: '1px solid black',
                                                borderStyle: 'none',
                                                boxShadow: 'none',
                                                zIndex: '3000',
                                                fontSize: '12px',
                                                borderRadius: '0',
                                            }),
                                            indicatorsContainer: (provided, state) => ({
                                                position: 'absolute',
                                                borderStyle: 'none',
                                                top: '50%',
                                                right: 10,
                                                transition: 'all .5s',
                                                transform: state.selectProps.menuIsOpen ? 'translateY(-50%) rotate(180deg)' : 'translateY(-50%)',
                                                zIndex: '3000',
                                                padding: '10px 0px',
                                                borderRadius: '0',
                                            }),
                                        }}
                                    />
                                </div>
                                <div className="pb-3">
                                    <input type="text" placeholder={sellWithUsData.sellWithUsSettings.city_label} value={city} onChange={e => setCity(e.target.value)} />
                                </div>
                                <div className="pb-3">
                                    <input type="text" placeholder={sellWithUsData.sellWithUsSettings.postcode_label} value={postCode} onChange={e => setPostCode(e.target.value)} />
                                </div>
                                <div className="pb-3">
                                    <input type="number" placeholder={sellWithUsData.sellWithUsSettings.phone_label} value={phone} onChange={e => setPhone(e.target.value)} />
                                </div>
                                <div className="pb-3">
                                    <input type="text" placeholder={sellWithUsData.sellWithUsSettings.email_label} value={email} onChange={e => setEmail(e.target.value)} />
                                </div>
                                <div className="pb-3">
                                    <input type="text" placeholder={sellWithUsData.sellWithUsSettings.website_label} value={websiteUrl} onChange={e => setWebsiteUrl(e.target.value)} />
                                </div>
                                <div className="type-checkbox">
                                    <h2 className="mb-0 pb-3">{sellWithUsData.sellWithUsSettings.gender_label}</h2>
                                    {
                                        sellWithUsData.genders.map(gender => (
                                            <label className="check-box me-5">
                                                <input type="radio" name="gender" value={gender.name} onChange={e => setGender(e.target.value)} key={gender.value} />
                                                <span className="checkmark"></span>
                                                <h6 className="mb-0">{gender.name}</h6>
                                            </label>
                                        ))
                                    }
                                </div>
                                <div className="pb-3">
                                    <DatePicker className="date-picker-age"
                                        showYearDropdown
                                        dropdownMode="select"
                                        isValidDate="true"
                                        placeholderText={sellWithUsData.sellWithUsSettings.age_label}
                                        value={startDate}
                                        selected={startDate}
                                        onChange={(age) => setStartDate(age)}
                                        maxDate={(new Date())}
                                    />
                                    {/* <Select classNamePrefix="custom-select" options={options} placeholder={sellWithUsData.sellWithUsSettings.age_label}
                                        styles={{

                                            menu: (provided, state) => ({
                                                ...provided,
                                                borderStyle: 'none',
                                                padding: '0px',
                                                borderRadius: '0',
                                            }),

                                            option: (provided, state) => ({
                                                color: state.isSelected ? 'black' : 'black',
                                                padding: '5px 20px 5px',
                                                fontSize: '14px',
                                                zIndex: '2000',
                                                borderStyle: 'none',
                                                textTransform: 'capitalize',
                                                cursor: "pointer",
                                                borderRadius: '0',
                                            }),

                                            control: (provided, state) => ({
                                                ...provided,
                                                borderBottom: '1px solid black',
                                                borderStyle: 'none',
                                                boxShadow: 'none',
                                                zIndex: '3000',
                                                fontSize: '14px',
                                                borderRadius: '0',
                                                width: '40%'
                                            }),

                                            indicatorsContainer: (provided, state) => ({
                                                position: 'absolute',
                                                borderStyle: 'none',
                                                top: '50%',
                                                right: 10,
                                                transition: 'all .5s',
                                                transform: state.selectProps.menuIsOpen ? 'translateY(-50%) rotate(180deg)' : 'translateY(-50%)',
                                                zIndex: '3000',
                                                padding: '10px 0px',
                                                borderRadius: '0',

                                            }),

                                        }}

                                    /> */}
                                </div>
                                <div className="pb-4">
                                    <input type="text" placeholder={sellWithUsData.sellWithUsSettings.occupation_label} value={occupation} onChange={e => setOccupation(e.target.value)} />
                                </div>
                                <label className="check-box">
                                    <input type="radio" name="terms" onChange={() => setTerms(1)} />
                                    <span className="checkmark"></span>
                                    <Link to="/terms-and-conditions">
                                        <h6 className="mb-0">{sellWithUsData.sellWithUsSettings.terms_label}</h6>
                                    </Link>
                                </label>
                                {
                                    errorMessages.first_name ?
                                        <h5 className="mt-2 mb-3">{errorMessages.first_name}</h5>
                                        :
                                        null
                                }
                                {
                                    errorMessages.last_name ?
                                        <h5 className="mt-2 mb-3">{errorMessages.last_name}</h5>
                                        :
                                        null
                                }
                                {
                                    errorMessages.account_type ?
                                        <h5 className="mt-2 mb-3">{errorMessages.account_type}</h5>
                                        :
                                        null
                                }
                                {
                                    errorMessages.address ?
                                        <h5 className="mt-2 mb-3">{errorMessages.address}</h5>
                                        :
                                        null
                                }
                                {
                                    errorMessages.region ?
                                        <h5 className="mt-2 mb-3">{errorMessages.region}</h5>
                                        :
                                        null
                                }
                                {
                                    errorMessages.city ?
                                        <h5 className="mt-2 mb-3">{errorMessages.city}</h5>
                                        :
                                        null
                                }
                                {
                                    errorMessages.phone ?
                                        <h5 className="mt-2 mb-3">{errorMessages.phone}</h5>
                                        :
                                        null
                                }
                                {
                                    errorMessages.email ?
                                        <h5 className="mt-2 mb-3">{errorMessages.email}</h5>
                                        :
                                        null
                                }
                                {
                                    errorMessages.website_url ?
                                        <h5 className="mt-2 mb-3">{errorMessages.website_url}</h5>
                                        :
                                        null
                                }
                                {
                                    errorMessages.gender ?
                                        <h5 className="mt-2 mb-3">{errorMessages.gender}</h5>
                                        :
                                        null
                                }
                                {
                                    errorMessages.accept_terms ?
                                        <h5 className="mt-2 mb-3">{errorMessages.accept_terms}</h5>
                                        :
                                        null
                                }

                                <div className="mt-4">
                                    <button className="button submit-button">
                                        {
                                            formLoading ?
                                                'Loading'
                                                :
                                                sellWithUsData.sellWithUsSettings.submit_label
                                        }
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </Layout>
    )
}