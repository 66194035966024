import Layout from "../components/Layout";
import { useEffect, useContext } from "react";
import GlobalState from "../GlobalState";
import axios from 'axios';
import { useState } from "react";

export default function ContactUs() {
    const { contactUsSettings } = useContext(GlobalState);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [errorMessages, setErrorMessages] = useState([]);
    const [successMessage, setSuccessMessage] = useState();
    const [formLoading, setFormLoading] = useState();

    useEffect(() => {
        window.triggerScroll();
    }, []);

    useEffect(() => {
    }, [errorMessages]);

    const contactSubmit = (e) => {
        e.preventDefault();

        window.grecaptcha.ready(function () {
            window.grecaptcha.execute('6LdIw9odAAAAANF7Qt-nFrcWRi4f6Kn8ZSKrwGqp', { action: 'submit' }).then(function (token) {
                // Add your logic to submit to your backend server here.

                if (formLoading) return;

                setFormLoading(true);

                axios.post('contact-submit', {
                    "name": name,
                    "email": email,
                    "subject": subject,
                    "message": message,
                    "token": token,
                })
                    .then(response => {
                        setName('');
                        setEmail('');
                        setSubject('');
                        setMessage('');
                        setErrorMessages([]);
                        setSuccessMessage(response.data);
                        setTimeout(() => {
                            setSuccessMessage('');
                        }, 3000);
                    })
                    .catch(error => {
                        setSuccessMessage('')
                        setErrorMessages(error.response.data.errors)
                    })

                    .finally(() => {
                        setFormLoading(false);
                    });
            });
        });


    }
    return (
        <Layout activePage="contactUs">
            <div className="container pb-5 pt-lg-5 mt-lg-3">
                <div className="sell-with-us-page">
                    <div className="pb-4 pt-5" animate="up">
                        <h1 className="mb-4">{contactUsSettings.page_title}</h1>
                    </div>
                    <div className="row">
                        <div className="col-lg-5 registration-form" animate="down">
                            <form onSubmit={contactSubmit} className="registration">
                                <div className="pb-3 ">
                                    <input type="text" placeholder={contactUsSettings.name_placeholder} value={name} onChange={e => setName(e.target.value)} />
                                </div>
                                <div className="pb-3">
                                    <input type="text" placeholder={contactUsSettings.email_placeholder} value={email} onChange={e => setEmail(e.target.value)} />
                                </div>
                                <div className="pb-3">
                                    <input type="text" placeholder={contactUsSettings.subject_placeholder} value={subject} onChange={e => setSubject(e.target.value)} />
                                </div>
                                <div className="pb-3">
                                    <textarea type="text" placeholder={contactUsSettings.message_placeholder} value={message} onChange={e => setMessage(e.target.value)} />
                                </div>
                                <h2 className={(successMessage ? "" : "d-none")}>{contactUsSettings.success_message}</h2>
                                {
                                    errorMessages.name ?
                                        <p className="error-message">{errorMessages.name}</p>
                                        :
                                        null
                                }

                                {
                                    errorMessages.email ?
                                        <p className="error-message">{errorMessages.email}</p>
                                        :
                                        null
                                }

                                {
                                    errorMessages.subject ?
                                        <p className="error-message">{errorMessages.subject}</p>
                                        :
                                        null
                                }

                                {
                                    errorMessages.message ?
                                        <p className="error-message">{errorMessages.message}</p>
                                        :
                                        null
                                }
                                <div className="mt-4">
                                    <button type="submit" className="button submit-button">
                                        {
                                            formLoading ?
                                                'Loading'
                                                :
                                                contactUsSettings.button_label
                                        }
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}