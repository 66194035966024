import { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Helmet } from "react-helmet";
import axios from 'axios';

function ScrollToTop({ history, children }) {

    const [seoPage, setSeoPage] = useState();

    useEffect(() => {
        const unlisten = history.listen(() => {
            if (!history.location.state?.noScrollTop) window.scrollTo(0, 0);

            axios.post('seo', {
                path: history.location.pathname
            })
                .then(r => {
                    setSeoPage(r.data)
                })
        });
        return () => {
            unlisten();
        }
    }, [history]);

    return seoPage ?
        <Helmet>
            <title>{seoPage.title}</title>
            <meta name="title" content={seoPage.title} />
            <meta name="description" content={seoPage.description} />

            <meta property="og:title" content={seoPage.title} />
            <meta property="og:description" content={seoPage.description} />
            <meta property="og:image" content={seoPage.image_path} />

            <meta property="twitter:title" content={seoPage.title} />
            <meta property="twitter:description" content={seoPage.description} />
            <meta property="twitter:image" content={seoPage.image_path} />
        </Helmet>
        :
        null;
}

export default withRouter(ScrollToTop);