import { Link, useParams } from "react-router-dom";
import Layout from "../components/Layout";
import { useEffect, useState, useContext } from "react";
import axios from 'axios';
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../components/Loader";
import GlobalState from "../GlobalState";

var currentPage = 1;

export default function SingleBrand(props) {
    let { slug } = useParams();
    const { addToWishlist, inWishlist, isFirstVisit } = useContext(GlobalState);
    const [singleBrand, setSingleBrand] = useState();
    const [products, setProducts] = useState();
    const [loading, setLoading] = useState(true);
    const [hasMore, setHasMore] = useState(true);

    const getData = (clearOldData = false) => {
        if (currentPage === 1) setLoading(true);

        axios.get('/single-brand/' + slug + '?page=' + currentPage + '&first_visit=' + isFirstVisit('brand', slug)).then(response => {
            setSingleBrand(response.data.brand);

            let oldProducts = [];
            if (!clearOldData && products) oldProducts = [...products];

            setProducts([...oldProducts, ...response.data.brandProducts.data]);
            currentPage++;

            setHasMore(response.data.brandProducts.current_page !== response.data.brandProducts.last_page);
            setLoading(false);

            window.triggerScroll();
        });
    }

    useEffect(() => {
        currentPage = 1;
        getData(true);
    }, [slug]);

    return (loading) ? <div className="fixed-top h-100 w-100 d-flex align-items-center justify-content-center">
        <Loader className="loader-home" />
    </div> : (
        <Layout activePage="shopBrand">

            <>
                <div className="container pt-lg-5 mt-lg-5">
                    <div className="row single-brand-page">
                        <div className={singleBrand.main_image_path ? "col-lg-5 col-md-12 order-lg-1 order-2" : "col-12 order-lg-2 order-1"}>
                            <h1 className="mb-4" >{singleBrand.name}</h1>
                            <div dangerouslySetInnerHTML={{ __html: singleBrand.description }} />
                        </div>
                        {
                            singleBrand.main_image_path ?
                                <div className={singleBrand.name && singleBrand.description ? "col-lg-7 col-md-12 order-lg-2 order-1 p-0 pb-lg-0 pb-5" : "col-12 p-0 order-lg-1 order-2"} >
                                    <div className={singleBrand.name && singleBrand.description ? "ratio balmain-image" : "ratio balmain-full-image"}>
                                        <img src={singleBrand.main_image_path} alt={singleBrand.name} title={singleBrand.name} />
                                    </div>
                                </div>
                                :
                                null
                        }
                    </div>
                </div>
                <InfiniteScroll
                    dataLength={products.length}
                    next={getData}
                    hasMore={hasMore}
                    loader={(
                        <>
                            {
                                hasMore ?
                                    <Loader />
                                    :
                                    null
                            }
                        </>
                    )}
                >
                    <div className="container">
                        <div className="single-brand-section">
                            <div className="container pt-lg-3 pb-lg-5">
                                <div className="row">
                                    {
                                        products.map((brandProduct, index) => (
                                            <div className="col-lg-4 col-md-4 col-sm-6 col-12 pb-5 position-relative" key={index}>
                                                <svg className="wishlist-item" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 422.4 375.8" xmlSpace="preserve" onClick={() => addToWishlist(brandProduct.slug)}>
                                                    <path className={(inWishlist(brandProduct.slug) ? '' : 'wishlist-path active')} d="M211.2,367.8c-5.8,0-11.3-2.1-15.6-5.9c-16.3-14.3-32.1-27.7-46-39.6l-0.1-0.1c-40.8-34.7-75.9-64.7-100.4-94.3  c-27.4-33-40.1-64.3-40.1-98.6c0-33.2,11.4-63.9,32.1-86.3C62,20.5,90.7,8,121.9,8c23.4,0,44.7,7.4,63.6,21.9  c9.5,7.3,18.1,16.3,25.7,26.8c7.6-10.5,16.2-19.5,25.7-26.8C255.7,15.3,277.1,8,300.5,8c31.2,0,60,12.5,80.9,35.2  c20.7,22.4,32.1,53.1,32.1,86.3c0,34.2-12.7,65.5-40.1,98.6c-24.5,29.5-59.7,59.5-100.4,94.3c-13.9,11.9-29.7,25.3-46.1,39.6  C222.5,365.8,217,367.8,211.2,367.8L211.2,367.8z" />
                                                </svg>
                                                <Link to={"/single-product/" + brandProduct.slug}  >
                                                    <div className="single-product position-relative">
                                                        <div className="ratio single-brand-img">
                                                            <img src={brandProduct.featured_image_path} alt={brandProduct.name} title={brandProduct.name} key={index} />
                                                        </div>
                                                        <h6 className="title mb-0 pt-4 pb-2">{brandProduct.name}</h6>
                                                        <p className="mt-4">{brandProduct.price}</p>
                                                    </div>
                                                </Link>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </InfiniteScroll>
            </>
        </Layout >
    )

}