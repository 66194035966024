import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages/Home";
import Magazine from "./pages/Magazine";
import ShopBrand from "./pages/ShopBrands";
import SingleBrand from "./pages/SingleBrand";
import SingleProduct from "./pages/SingleProduct";
import WishList from "./pages/WishList";
import SingleArticle from "./pages/SingleArticle";
import HoroscopeMagazine from "./pages/HoroscopeMagazine";
import SingleHoroscope from "./pages/SingleHoroscope";
import MagazineHoroscopeArchive from "./pages/MagazineHoroscopeArchive";
import SellWithUs from "./pages/SellWithUs";
import SellWithUsForm from "./pages/SellWithUsForm";
import SellWithUsFormSuccess from "./pages/SellWithUsFormSuccess";
import AboutUs from "./pages/AboutUs";
import TermsAndConditions from "./pages/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Oops from "./pages/Oops";
import ContactUs from "./pages/ContactUs";
import ScrollToTop from "./components/ScrollToTop";
import { Fragment } from "react";
import SearchPage from "./pages/SearchPage";
import { useEffect, useState } from "react";
import GlobalState from "./GlobalState";
import axios from 'axios';
import JustLanded from "./pages/JustLanded";
import Category from "./pages/Category";
import ArchiveMagazine from "./pages/ArchiveMagazine";
import Loader from "./components/Loader";


axios.defaults.baseURL = 'https://www.stilorama.com/api';

export default function App(props) {

    const [loading, setLoading] = useState(true);
    const [headerSettings, setHeaderSettings] = useState();
    const [footerSettings, setFooterSettings] = useState();
    const [informativePages, setInformativePages] = useState();
    const [socialChannels, setSocialChannels] = useState();
    const [contactUsSettings, setcontactUsSettings] = useState();
    const [horoscopesSettings, setHoroscopesSettings] = useState();
    const [sellWithUsData, setSellWithUsData] = useState();
    const [brandsHeader, setBrandsHeader] = useState();
    const [productsCategories, setProductsCategories] = useState();
    const [magazineCategories, setMagazineCategories] = useState();
    const [wishlistItems, setWishlistItems] = useState([]);
    const [wishlistItemsDetails, setWishlistItemsDetails] = useState([]);
    const [wishlistItemsDetailsLoading, setWishlistItemsDetailsLoading] = useState(true);
    const [wishlistOpen, setWishlistOpen] = useState(false);
    const [acceptedCookies, setAcceptedCookies] = useState(true);

    useEffect(() => {
        let wishlistItemsLocalStorage = localStorage.getItem('wishlistItems');
        if (wishlistItemsLocalStorage) {
            setWishlistItems(JSON.parse(wishlistItemsLocalStorage));
        }

        axios.get('general-data').then(response => {
            setHeaderSettings(response.data.headerSettings);
            setFooterSettings(response.data.footerSettings);
            setInformativePages(response.data.informativePages);
            setSocialChannels(response.data.socialChannels);
            setcontactUsSettings(response.data.contactUsSettings);
            setHoroscopesSettings(response.data.horoscopesSettings);
            setSellWithUsData(response.data.sellWithUsData);
            setBrandsHeader(response.data.brandsHeader);
            setProductsCategories(response.data.productsCategories);
            setMagazineCategories(response.data.magazineCategories);
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        if (wishlistItems.length) {
            localStorage.setItem('wishlistItems', JSON.stringify(wishlistItems));
            setWishlistItemsDetailsLoading(true);
            axios.post('get-whishlist-data', {
                slugs: wishlistItems
            }).then(response => {
                setWishlistItemsDetails(response.data.products);
                setWishlistItemsDetailsLoading(false);
                window.triggerScroll();
            });
        } else {
            setWishlistItemsDetails([]);
            setWishlistItemsDetailsLoading(false);
        }
    }, [wishlistItems]);

    const addToWishlist = (slug) => {
        if (acceptedCookies) {
            let newWishlistItems = [...wishlistItems];
            let wishlistItemIndex = newWishlistItems.indexOf(slug);
            if (wishlistItemIndex === -1) {
                newWishlistItems.push(slug);
                axios.get('increment-whishlist-count/' + slug);
            }
            else {
                newWishlistItems.splice(wishlistItemIndex, 1);
                axios.get('decrement-whishlist-count/' + slug);
            }
            setWishlistItems(newWishlistItems);
            setWishlistOpen(true);
        }
    }

    const inWishlist = (slug) => {
        return wishlistItems.indexOf(slug) !== -1;
    }

    const isFirstVisit = (type, slug) => {
        let localStorageKey = 'visits-' + type;
        let visits = localStorage.getItem(localStorageKey) ? JSON.parse(localStorage.getItem(localStorageKey)) : [];
        let firstVisit = visits.indexOf(slug) === -1;

        if (firstVisit) {
            visits.push(slug);
            localStorage.setItem(localStorageKey, JSON.stringify(visits));
        }

        return firstVisit ? 1 : 0;
    }

    if (loading) {
        return (
            <div className="fixed-top h-100 w-100 d-flex align-items-center justify-content-center">
                <Loader className="loader-home" />
            </div>
        )
    }

    return (
        <GlobalState.Provider value={{
            headerSettings,
            footerSettings,
            informativePages,
            socialChannels,
            contactUsSettings,
            horoscopesSettings,
            sellWithUsData,
            brandsHeader,
            productsCategories,
            magazineCategories,
            wishlistItems,
            setWishlistItems,
            inWishlist,
            addToWishlist,
            wishlistItemsDetails,
            wishlistItemsDetailsLoading,
            wishlistOpen,
            setWishlistOpen,
            acceptedCookies,
            setAcceptedCookies,
            isFirstVisit,
        }}>
            <Router>
                <Fragment>
                    <ScrollToTop />
                    <Switch>
                        <Route path="/" exact={true}>
                            <Home />
                        </Route>
                        <Route path="/search/:query">
                            <SearchPage />
                        </Route>
                        <Route path="/contact-us">
                            <ContactUs />
                        </Route>
                        <Route path="/oops">
                            <Oops />
                        </Route>
                        <Route path="/privacy-policy">
                            <PrivacyPolicy />
                        </Route>
                        <Route path="/terms-and-conditions">
                            <TermsAndConditions />
                        </Route>
                        <Route path="/about-us">
                            <AboutUs />
                        </Route>
                        <Route path="/sell-with-us-form-success">
                            <SellWithUsFormSuccess />
                        </Route>
                        <Route path="/sell-with-us-form">
                            <SellWithUsForm />
                        </Route>
                        <Route path="/sell-with-us">
                            <SellWithUs />
                        </Route>
                        <Route path="/magazine-horoscope-archive/:slug">
                            <MagazineHoroscopeArchive />
                        </Route>
                        <Route path="/single-horoscope/:slug/:date">
                            <SingleHoroscope />
                        </Route>
                        <Route path="/horoscope-magazine">
                            <HoroscopeMagazine />
                        </Route>
                        <Route path="/single-article/:slug">
                            <SingleArticle />
                        </Route>
                        <Route path="/wishlist">
                            <WishList />
                        </Route>
                        <Route path="/just-landed">
                            <JustLanded />
                        </Route>
                        <Route path="/single-product/:slug">
                            <SingleProduct />
                        </Route>
                        <Route path="/category/:categorySlug/:subcategorySlug?">
                            <Category />
                        </Route>
                        <Route path="/single-brand/:slug">
                            <SingleBrand />
                        </Route>
                        <Route path="/shop-brands">
                            <ShopBrand />
                        </Route>
                        <Route path="/magazine/:slug?">
                            <Magazine />
                        </Route>
                        <Route path="/archive-magazine/:slug">
                            <ArchiveMagazine />
                        </Route>
                        <Route path="">
                            <Oops />
                        </Route>
                    </Switch>
                </Fragment>
            </Router>
        </GlobalState.Provider>
    );
}

