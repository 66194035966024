import Layout from "../components/Layout";
import { useEffect, useState, useContext } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useParams } from "react-router-dom";
import GlobalState from "../GlobalState";
import axios from 'axios';
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../components/Loader";

var currentPage = 1;

export default function MagazineHoroscopeArchive(props) {
    const { horoscopesSettings } = useContext(GlobalState);
    const [startDate, setStartDate] = useState();
    const [horoscopeArchive, setHoroscopeArchive] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [noResults, setNoResults] = useState(false);
    let { slug } = useParams();

    const getData = (clearOldData = false) => {
        setNoResults(false);
        axios.post('horoscope-archive', {
            slug: slug,
            page: currentPage,
        }).then(response => {
            let oldHoroscopeArchive = [];
            if (!clearOldData && horoscopeArchive) oldHoroscopeArchive = [...horoscopeArchive];
            setHoroscopeArchive([...horoscopeArchive, ...response.data.horoscopeArchive.data]);
            currentPage = currentPage + 1;

            setHasMore(response.data.horoscopeArchive.current_page !== response.data.horoscopeArchive.last_page);
            window.triggerScroll();
        });
    }

    useEffect(() => {
        if (startDate) {
            let date = new Date(startDate);
            let year = date.getFullYear();
            let month = date.getMonth() + 1;
            let day = date.getDate();

            month = month < 10 ? ("0" + month) : month;
            day = day < 10 ? ("0" + day) : day;

            let dateString = day + '/' + month + '/' + year;

            setNoResults(false);
            axios.post('horoscope-filter-by-date', {
                slug: slug,
                date: dateString
            }).then(response => {
                setHoroscopeArchive([{ ...response.data }]);
                setHasMore(false);

                window.triggerScroll();
            }).catch(() => {
                setNoResults(true);
            });
        } else {
            currentPage = 1;
            getData(true);
        }
    }, [startDate, slug]);

    return (horoscopeArchive) ? (
        <Layout activeHoroscopePage="horoscope" activePage="magazine">
            <div className="container pt-lg-5 mt-lg-3">
                <div className="archive-page">
                    <div className="pb-4 pt-5">
                        <h1 className="mb-5 pb-lg-5">{horoscopesSettings.archive_title}</h1>
                        <div className="row align-items-center pb-4">
                            <div className="col-auto">
                                <p className="mb-lg-0 mb-md-0 mb-sm-0 mb-2">{horoscopesSettings.filter_label}</p>
                            </div>
                            <div className="col-auto index">
                                <DatePicker className="date-picker"
                                    placeholderText={horoscopesSettings.date_placeholder}
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)}
                                    showYearDropdown
                                    dropdownMode="select"
                                    isClearable
                                    maxDate={(new Date())}
                                />
                            </div>
                        </div>
                        {
                            noResults ?
                                <p className="text-center">{horoscopesSettings.no_results_label}</p>
                                :

                                <InfiniteScroll
                                    dataLength={horoscopeArchive.length}
                                    next={getData}
                                    hasMore={hasMore}
                                    loader={(
                                        <>
                                            {
                                                hasMore ?
                                                    <Loader /> :
                                                    null
                                            }
                                        </>
                                    )}
                                >
                                    <div>
                                        <div className="row justify-content-center gx-0 pt-5">
                                            {
                                                horoscopeArchive.map((horoscopeArchiveSingle, index) => {
                                                    return (
                                                        <div key={index.toString()} className="col-lg-4 col-md-4 col-sm-6 col-12 py-5 add-padding" animate="left">
                                                            <Link to={"/single-horoscope/" + horoscopeArchiveSingle.horoscope.slug + '/' + horoscopeArchiveSingle.date}>
                                                                <div className="ratio ratio-1x1">
                                                                    <img src={horoscopeArchiveSingle.horoscope.icon_path} alt={horoscopeArchiveSingle.title} title={horoscopeArchiveSingle.title} />
                                                                </div>
                                                                <h5 className="text-center mt-5 mb-0">{horoscopeArchiveSingle.title}</h5>
                                                                <div className="line my-4"></div>
                                                                <h6 className="text-center">{horoscopeArchiveSingle.date}</h6>
                                                            </Link>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </InfiniteScroll>

                        }
                    </div>
                </div>
            </div>
        </Layout>
    )
        :
        <div className="fixed-top h-100 w-100 d-flex align-items-center justify-content-center">
            <Loader className="loader-home" />
        </div>
}