import Layout from "../components/Layout";
import { Link } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import axios from 'axios';
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../components/Loader";
import GlobalState from "../GlobalState";

export default function JustLanded(props) {

    const [productsSettings, setProductsSettings] = useState();
    const [justLanded, setJustLanded] = useState();
    const [hasMore, setHasMore] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const { addToWishlist, inWishlist } = useContext(GlobalState);

    const getData = () => {
        axios.get('/just-landed?page=' + currentPage).then(response => {
            let oldJustLanded = justLanded ? [...justLanded] : [];
            setJustLanded([...oldJustLanded, ...response.data.products.data]);
            setCurrentPage(currentPage + 1);
            window.triggerScroll();

            setHasMore(response.data.products.current_page !== response.data.products.last_page);
            axios.post('products-settings').then(response => {
                setProductsSettings(response.data);
                window.triggerScroll();
            });

        });
    }

    useEffect(() => {
        getData();
        
    }, []);

    return (productsSettings && justLanded) ? (
        <Layout activeCategoryPage="just-landed" >
            <div className="container px-lg-0 pt-lg-5 mt-lg-3">
                <div className="designers pb-3 pt-5" >
                    <h1>{productsSettings[0].just_landed_title}</h1>
                </div>

                <InfiniteScroll
                    dataLength={justLanded ? justLanded.length : 0}
                    next={getData}
                    hasMore={hasMore}
                    loader={(
                        <>
                            {
                                hasMore ?
                                    <Loader></Loader>
                                    :
                                    null
                            }
                        </>
                    )}
                >

                    <div className="single-brand-section">
                        <div className="row  pt-lg-3 ">
                            {
                                justLanded.map(product => (
                                    <div className="col-lg-4 col-md-4 col-sm-6 col-12 pb-5" animate="left" key={product.id}>
                                        <svg className="wishlist-item" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 422.4 375.8" xmlSpace="preserve" onClick={() => addToWishlist(product.slug)}>
                                            <path className={(inWishlist(product.slug) ? '' : 'wishlist-path active')} d="M211.2,367.8c-5.8,0-11.3-2.1-15.6-5.9c-16.3-14.3-32.1-27.7-46-39.6l-0.1-0.1c-40.8-34.7-75.9-64.7-100.4-94.3  c-27.4-33-40.1-64.3-40.1-98.6c0-33.2,11.4-63.9,32.1-86.3C62,20.5,90.7,8,121.9,8c23.4,0,44.7,7.4,63.6,21.9  c9.5,7.3,18.1,16.3,25.7,26.8c7.6-10.5,16.2-19.5,25.7-26.8C255.7,15.3,277.1,8,300.5,8c31.2,0,60,12.5,80.9,35.2  c20.7,22.4,32.1,53.1,32.1,86.3c0,34.2-12.7,65.5-40.1,98.6c-24.5,29.5-59.7,59.5-100.4,94.3c-13.9,11.9-29.7,25.3-46.1,39.6  C222.5,365.8,217,367.8,211.2,367.8L211.2,367.8z" />
                                        </svg>
                                        <Link to={"/single-product/" + product.slug} >
                                            <div className="single-product position-relative">
                                                <div className="ratio single-brand-img">
                                                    <img src={product.featured_image_path} alt={product.name} title={product.name} />
                                                </div>

                                                <h6 className="title mb-0 pt-4 pb-2">{product.name}</h6>
                                                <p className="mt-4">{product.price}</p>
                                            </div>
                                        </Link>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </InfiniteScroll>
            </div>
        </Layout >
    )
    :
    <div className="fixed-top h-100 w-100 d-flex align-items-center justify-content-center">
        <Loader className="loader-home" />
    </div>
}