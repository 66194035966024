import Layout from "../components/Layout";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { useEffect, useContext } from "react";
import axios from "axios";
import { useState } from "react";
import Loader from "../components/Loader";
import GlobalState from "../GlobalState";

export default function Home() {
    const [homeData, setHomeData] = useState();
    const { addToWishlist, inWishlist } = useContext(GlobalState);

    SwiperCore.use([Navigation]);
    useEffect(() => {
        axios.get('homepage-data').then(response => {
            setHomeData(response.data)
            window.triggerScroll();
        });
    }, []);

    return homeData ? (
        <Layout>
            <>
                <div className="section-1 py-lg-5">
                    <div className="container py-lg-5 pb-5 pb-lg-0 pb-md-0">
                        <div className="home-slider">
                            {homeData.slides.length !== 1 ?
                                <Swiper
                                    // className="mySwiper"
                                    loop={true}
                                    slidesPerView={1}
                                    spaceBetween={0}
                                    navigation={{
                                        nextEl: '.swiper-button-next',
                                        prevEl: '.swiper-button-prev'
                                    }}
                                >
                                    {
                                        homeData.slides.map(slide => (
                                            <SwiperSlide key={slide.id}>
                                                <div className="row align-items-center text-center justify-content-center g-0">
                                                    <div className="col-lg-1 col-md-1 order-md-1 order-1 "></div>
                                                    <div className={slide.image_path ? "col-lg-6 col-md-6 col-10 text-center order-md-2 order-4 p-0" : "col-12 "}>
                                                        <h1 className="mb-4">{slide.title}</h1>
                                                        <p>{slide.subtitle}</p>
                                                        {
                                                            slide.button_url &&
                                                                slide.button_url.includes("http") ?

                                                                <a href={slide.button_url} rel="noreferrer" className="external-button">{slide.button_label}</a>

                                                                :
                                                                null
                                                        }
                                                    </div>
                                                    <div className="col-lg-1 col-md-1 order-3"></div>
                                                    {slide.image_path ?
                                                        <div className={slide.title && slide.subtitle && slide.button_label ? "col-lg-4 col-md-4 col-12 order-md-4 order-2 pb-lg-0 pb-5" : "col-12"}>
                                                            <div className={slide.title && slide.subtitle && slide.button_label ? "ratio header-image" : "ratio header-full-image"}>
                                                                <img src={slide.image_path} alt={slide.title} title={slide.title} />
                                                            </div>
                                                        </div>
                                                        : null
                                                    }
                                                </div>
                                            </SwiperSlide>
                                        ))
                                    }
                                </Swiper>
                                :
                                homeData.slides.map(slide => (
                                    <div className="row align-items-center text-center justify-content-center" key={slide.id}>
                                        <div className="col-lg-1 col-md-1 order-md-1 order-1 "></div>
                                        <div className={slide.image_path ? "col-lg-6 col-md-6 col-10 text-center order-md-2 order-4 p-0" : "col-12 text-start"} animate="left">
                                            <h1 className="mb-4">{slide.title}</h1>
                                            <p>{slide.subtitle}</p>
                                            {
                                                // slide.button_url.includes("https") ?
                                                <a href={slide.button_url} rel="noreferrer" className="external-button">{slide.button_label}</a>
                                                // :
                                                // null
                                            }
                                        </div>
                                        <div className="col-lg-1 col-md-1 order-3"></div>
                                        <div className={slide.title && slide.subtitle && slide.button_label ? "col-lg-4 col-md-4 col-12 order-md-4 order-2 pb-lg-0 pb-5" : "col-12"} animate="right">

                                            <div className={slide.title && slide.subtitle && slide.button_label ? "ratio header-image" : "ratio header-full-image"}>
                                                <img src={slide.image_path} alt={slide.title} title={slide.title} />
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>


                <div className="section-2">
                    <div className="container py-5">
                        <div className="row" animate="right">
                            <h1 className="mb-4">{homeData.settings[0].editors_title}</h1>
                            <p>{homeData.settings[0].editors_description}</p>
                        </div>

                        <div className="editors" animate="right">
                            {
                                homeData ?
                                    <Swiper className="mySwiper"
                                        loop={false}
                                        slidesPerView={1.4}
                                        spaceBetween={20}
                                        // centeredSlides={true}
                                        breakpoints={{
                                            991.98: {
                                                // loop: true,
                                                slidesPerView: 3,
                                                allowTouchMove: false,
                                            },

                                            // when window width is >= 767.98px
                                            767.98: {
                                                slidesPerView: 2,
                                                allowTouchMove: true,
                                            },
                                        }}
                                    >
                                        {
                                            homeData.editorPicks.map(editor => (
                                                <SwiperSlide key={editor.id}>
                                                    {editor.button_url.includes("http") ?
                                                        <a href={editor.button_url.includes("http") ? editor.button_url : "#"} rel="noreferrer">
                                                            <div className="editors-section d-flex h-100 flex-column" >
                                                                <div className=" ratio editor-image">
                                                                    <img src={editor.image_path} alt={editor.title} />
                                                                </div>

                                                                <div className="section-paragraph">
                                                                    <h6 className="pt-4">{editor.title}</h6>
                                                                    <p className="editor-paragraph">{editor.excerpt}</p>
                                                                </div>
                                                                {
                                                                    editor.button_url.includes("http") ?
                                                                        <div>
                                                                            <a href={editor.button_url} rel="noreferrer" className="external-button">{editor.button_label}</a>
                                                                        </div>
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                        </a>
                                                        :
                                                        <div className="editors-section d-flex h-100 flex-column" >
                                                            <div className=" ratio editor-image">
                                                                <img src={editor.image_path} alt={editor.title} title={editor.title} />
                                                            </div>
                                                            <div className="section-paragraph">
                                                                <h6 className="pt-4">{editor.title}</h6>
                                                                <p className="editor-paragraph">{editor.excerpt}</p>
                                                            </div>
                                                            {
                                                                editor.button_url.includes("http") ?
                                                                    <div>
                                                                        <a href={editor.button_url} rel="noreferrer" className="external-button">{editor.button_label}</a>
                                                                    </div>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                    }
                                                </SwiperSlide>
                                            ))
                                        }
                                    </Swiper>
                                    :
                                    <Loader />
                            }
                        </div>
                    </div>
                </div>


                <div className="section-3">
                    <div className="container py-5">
                        <div className="row" animate="left">
                            <div className="text-start text-lg-end">
                                <h1 className="mb-4">{homeData.settings[0].designer_title}</h1>
                                <p>{homeData.settings[0].designer_description}</p>
                            </div>
                        </div>

                        <div className="editors" animate="left">
                            <Swiper className="mySwiper"
                                loop={false}
                                slidesPerView={1.4}
                                spaceBetween={20}
                                // centeredSlides={true}
                                breakpoints={{
                                    1399.98: {
                                        slidesPerView: 4,
                                        allowTouchMove: false,
                                    },
                                    1199.98: {
                                        slidesPerView: 4,
                                        allowTouchMove: false,
                                    },
                                    991.98: {
                                        // loop: true,
                                        slidesPerView: 4,
                                        allowTouchMove: false,
                                    },

                                    // when window width is >= 767.98px
                                    767.98: {
                                        slidesPerView: 2,
                                        allowTouchMove: true,
                                    },
                                }}
                            >
                                {
                                    homeData.designers.map(designer => (

                                        <SwiperSlide key={designer.id}>
                                            {
                                                designer.button_url.includes("http") ?
                                                    <a href={designer.button_url.includes("http") ? designer.button_url : "#"} rel="noreferrer">
                                                        <div className="shop-section d-flex h-100 flex-column" >
                                                            <div className="ratio ratio-1x1 shop-image">
                                                                <img src={designer.image_path} alt={designer.title} title={designer.title} />
                                                            </div>
                                                            <h6 className="pt-4 ">{designer.title}</h6>
                                                            <p className="editor-paragraph flex-grow-1">{designer.excerpt} </p>
                                                            {
                                                                designer.button_url.includes("http") ?
                                                                    <div>
                                                                        <a href={designer.button_url} rel="noreferrer" className="external-button">{designer.button_label}</a>
                                                                    </div>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                    </a>
                                                    :
                                                    <div className="shop-section d-flex h-100 flex-column" >
                                                        <div className="ratio ratio-1x1 shop-image">
                                                            <img src={designer.image_path} alt={designer.title} title={designer.title} />
                                                        </div>
                                                        <h6 className="pt-4 ">{designer.title}</h6>
                                                        <p className="editor-paragraph flex-grow-1">{designer.excerpt} </p>
                                                        {
                                                            designer.button_url.includes("http") ?
                                                                <div>
                                                                    <a href={designer.button_url} rel="noreferrer" className="external-button">{designer.button_label}</a>
                                                                </div>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                            }
                                        </SwiperSlide>
                                    ))
                                }
                            </Swiper>
                        </div>
                    </div>
                </div>

                <div className="section-4">
                    <div className="container py-5">
                        <div className="position-relative">
                            <div className="row justify-content-center" animate="right">
                                <div className="col-lg-8 text-start text-lg-center">
                                    <h1 className="mb-4">{homeData.settings[0].just_landed_title}</h1>
                                    <p>{homeData.settings[0].just_landed_subtitle} </p>
                                </div>
                            </div>
                            <div className="landed-section d-none d-lg-block">
                                <Link to="/just-landed" className="external-button">{homeData.settings[0].just_landed_button_label}</Link>
                            </div>
                        </div>
                        <div className="landed px-5" animate="right">
                            <Swiper className="mySwiper"
                                loop={true}
                                slidesPerView={1}
                                spaceBetween={30}
                                navigation={{
                                    nextEl: '.swiper-button-next',
                                    prevEl: '.swiper-button-prev'
                                }}
                                breakpoints={{

                                    1399.98: {
                                        slidesPerView: 5,
                                    },

                                    991.98: {
                                        slidesPerView: 4,
                                    },

                                    // when window width is >= 767.98px
                                    767.98: {
                                        slidesPerView: 3,
                                    },

                                    575.98: {
                                        slidesPerView: 2,
                                    },

                                }}>

                                {

                                    homeData.products.map(product => (
                                        <SwiperSlide className="position-relative" key={product.id}>
                                            <svg className={"wishlist-swiper"} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 422.4 375.8" xmlSpace="preserve" onClick={() => addToWishlist(product.slug)}>
                                                <path className={(inWishlist(product.slug) ? '' : 'wishlist-path active')} d="M211.2,367.8c-5.8,0-11.3-2.1-15.6-5.9c-16.3-14.3-32.1-27.7-46-39.6l-0.1-0.1c-40.8-34.7-75.9-64.7-100.4-94.3  c-27.4-33-40.1-64.3-40.1-98.6c0-33.2,11.4-63.9,32.1-86.3C62,20.5,90.7,8,121.9,8c23.4,0,44.7,7.4,63.6,21.9  c9.5,7.3,18.1,16.3,25.7,26.8c7.6-10.5,16.2-19.5,25.7-26.8C255.7,15.3,277.1,8,300.5,8c31.2,0,60,12.5,80.9,35.2  c20.7,22.4,32.1,53.1,32.1,86.3c0,34.2-12.7,65.5-40.1,98.6c-24.5,29.5-59.7,59.5-100.4,94.3c-13.9,11.9-29.7,25.3-46.1,39.6  C222.5,365.8,217,367.8,211.2,367.8L211.2,367.8z" />
                                            </svg>
                                            <Link to={"/single-product/" + product.slug}>
                                                <div className="swiper-content d-flex h-100 flex-column">
                                                    <div className="ratio swiper-image product-img">
                                                        <img src={product.featured_image_path} alt={product.name} title={product.name} />
                                                    </div>
                                                    <div className="flex-grow-1 d-flex flex-column">
                                                        <div className=" content">
                                                            <div className="p-3 flex-grow-1">
                                                                <div className=" flex-grow-1">
                                                                    <h4 className="">{product.name}</h4>
                                                                </div>
                                                            </div>
                                                            <p className="p-3">{product.price}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </SwiperSlide>
                                    ))
                                }
                            </Swiper>
                            <div className="swiper-button-prev menu-swiper">
                                <img src="/assets/images/prev.svg" alt="prev " />
                            </div>
                            <div className="swiper-button-next menu-swiper">
                                <img src="/assets/images/next.svg" alt="next" />
                            </div>
                        </div>
                        <div className="text-center pt-5 d-lg-none">
                            <Link to="/just-landed" className="external-button">{homeData.settings[0].just_landed_button_label}</Link>
                        </div>
                    </div>
                </div>


                <div className="section-5">
                    <div className="container py-5">
                        <div className="row" animate="left">
                            <div className="col-lg-8">
                                <h1 className="mb-4">{homeData.settings[0].stories_title}</h1>
                                <p>{homeData.settings[0].stories_description}</p>
                            </div>
                        </div>
                        <div className="row">
                            {
                                homeData.settings[0]?.stories.map(story => (
                                    <div className="col-lg-3 col-md-3 col-sm-6 col-12 pb-4 pb-lg-0" animate="left" key={story.id}>
                                        <Link to={"/single-article/" + story.slug}>
                                            <div className="story-section">
                                                <div className="ratio ratio-1x1 story-image">
                                                    <img src={story.featured_image_path} alt={story.title} title={story.title} />
                                                </div>
                                                <h6 className="pt-4 ">{story.title}</h6>
                                                <p className="editor-paragraph mb-4">{story.excerpt}</p>
                                                {/* <div className="seperator" /> */}
                                                {/* <p className="date mt-4">20 SEPTEMBER 2021</p> */}
                                            </div>
                                        </Link>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </>

        </Layout >
    )
        :
        <div className="fixed-top h-100 w-100 d-flex align-items-center justify-content-center">
            <Loader className="loader-home" />
        </div>
}