import Layout from "../components/Layout";
import { useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { useState } from "react";
import axios from "axios";
import Loader from "../components/Loader";
import GlobalState from "../GlobalState";

SwiperCore.use([Navigation]);

export default function SingleArticle(props) {
    const { addToWishlist, inWishlist, isFirstVisit } = useContext(GlobalState);
    let { slug } = useParams();
    const [singleArticle, setSingleArticle] = useState()
    useEffect(() => {
        axios.get('/magazine-single-article/' + slug + '?first_visit=' + isFirstVisit('magazine', slug)).then(response => {
            setSingleArticle(response.data);
            window.triggerScroll();
        });
    }, [slug]);
    return (singleArticle && singleArticle.article.featured_products) ? (
        <Layout activePage="magazine">
            <>
                <div className="container py-5 pb-5 pt-lg-5 mt-lg-5">
                    <div className="single-article clearfix">
                        {/* <div className="row" animate="left">
                                    <div className="col-lg-4 col-md-12 p-0 pb-lg-0 pb-lg-1"> */}
                        <div className="article-page me-4 mb-3">
                            <div className="ratio ratio-single-article" animate="left">
                                <img src={singleArticle.article.main_image_path} alt={singleArticle.article.title} title={singleArticle.article.title} />
                            </div>
                        </div>
                        {/* </div>
                                    <div className="col-lg-8 pt-4 pt-lg-0" animate="right"> */}
                        <h1 className="mb-4" animate="right">{singleArticle.article.title}</h1>
                        <div dangerouslySetInnerHTML={{ __html: singleArticle.article.content }} animate="right" />
                        {/* </div>
                                </div> */}
                    </div>
                </div>
                <div className="container pb-5">
                    <h1 className="text-lg-center text-start" animate="left">{singleArticle.article.featured_products_title}</h1>

                    <div animate="left">
                        <Swiper className="mySwiper"
                            loop={false}
                            slidesPerView={1.4}
                            spaceBetween={20}
                            centeredSlides={true}
                            breakpoints={{
                                991.98: {
                                    slidesPerView: 4,
                                    allowTouchMove: false,
                                    centeredSlides: false,
                                },
                                // when window width is >= 767.98px
                                767.98: {
                                    slidesPerView: 2,
                                },
                            }}>
                            {
                                singleArticle.article.featured_products.map(product => (
                                    <SwiperSlide className="position-relative">
                                        <svg className="wishlist-brand" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 422.4 375.8" xmlSpace="preserve" onClick={() => addToWishlist(product.slug)}>
                                            <path className={(inWishlist(product.slug) ? '' : 'wishlist-path active')} d="M211.2,367.8c-5.8,0-11.3-2.1-15.6-5.9c-16.3-14.3-32.1-27.7-46-39.6l-0.1-0.1c-40.8-34.7-75.9-64.7-100.4-94.3  c-27.4-33-40.1-64.3-40.1-98.6c0-33.2,11.4-63.9,32.1-86.3C62,20.5,90.7,8,121.9,8c23.4,0,44.7,7.4,63.6,21.9  c9.5,7.3,18.1,16.3,25.7,26.8c7.6-10.5,16.2-19.5,25.7-26.8C255.7,15.3,277.1,8,300.5,8c31.2,0,60,12.5,80.9,35.2  c20.7,22.4,32.1,53.1,32.1,86.3c0,34.2-12.7,65.5-40.1,98.6c-24.5,29.5-59.7,59.5-100.4,94.3c-13.9,11.9-29.7,25.3-46.1,39.6  C222.5,365.8,217,367.8,211.2,367.8L211.2,367.8z" />
                                        </svg>
                                        <Link to={"/single-product/" + product.slug}>
                                            <div className="single-product position-relative">
                                                <div className="ratio single-brand-img">
                                                    <img src={product.featured_image_path} alt={product.name} title={product.name} />
                                                </div>
                                                <h6 className="title mb-0 pt-3 pb-2">{product.name}</h6>
                                                <p className="mt-4">{product.price}</p>
                                            </div>
                                        </Link>
                                    </SwiperSlide>
                                ))
                            }
                        </Swiper>
                    </div>
                </div>
                )
            </>
        </Layout >
    )
        :
        <div className="fixed-top h-100 w-100 d-flex align-items-center justify-content-center">
            <Loader className="loader-home" />
        </div>
}