import Layout from "../components/Layout";
import { useEffect,  useContext } from "react";
import { Link } from "react-router-dom";
import GlobalState from "../GlobalState";

export default function SellWithUs() {
    const { sellWithUsData } = useContext(GlobalState);
    useEffect(() => {
        window.triggerScroll();
    }, []);
    return (
        <Layout activePage="sellWithUs">
            <div className="container pb-5 px-lg-0 pt-lg-5 mt-lg-3 ">
                <div className="sell-with-us-page pt-5 pb-3 ">
                    <div className="pb-4 " animate="up">
                        <h1 className="mb-4 ">{sellWithUsData.sellWithUsSettings.page_title}</h1>
                        <div dangerouslySetInnerHTML={{ __html: sellWithUsData.sellWithUsSettings.page_description }} />
                    </div>
                    <div className="row">
                        <div className="col-lg-6 mb-5" animate="down">
                            <h3 className="mb-4">{sellWithUsData.sellWithUsSettings.register_cta_title}</h3>
                            <div className="mb-0" dangerouslySetInnerHTML={{ __html: sellWithUsData.sellWithUsSettings.register_cta_description }} />
                            <div className="mt-5">
                                <Link to="/sell-with-us-form" className="button fillform-button">{sellWithUsData.sellWithUsSettings.register_cta_button_label}</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}