import Layout from "../components/Layout";
import { useEffect } from "react";
import { useContext } from "react";
import GlobalState from "../GlobalState";

export default function Oops() {
    const { informativePages } = useContext(GlobalState);
    useEffect(() => {
        window.triggerScroll();
    }, []);
    return (
        <Layout>
              <div className="container pb-5">
                <div className="sell-with-us-page pb-4 pt-5">
                    <div className="pb-4 pt-5" animate="up">
                        <h1 className="mb-4">{informativePages.error_title}</h1>
                        <div dangerouslySetInnerHTML={{ __html: informativePages.error_content }} />
                    </div>
                </div>
            </div>
        </Layout>
    )
}
