import Layout from "../components/Layout";
import { useEffect, useContext } from "react";
import GlobalState from "../GlobalState";

export default function AboutUs() {
    const { informativePages } = useContext(GlobalState);
    useEffect(() => {
        window.triggerScroll();
    }, []);

    return (
        <Layout activePage="aboutUs">
            <div className="container pb-5 pt-lg-5 mt-lg-3">
                <div className="sell-with-us-page pb-4 pt-5">
                    <div className="pb-4 pt-5" animate="up">
                        <h1 className="mb-4 ">{informativePages.about_us_title}</h1>
                        <div dangerouslySetInnerHTML={{ __html: informativePages.about_us_content }} />
                    </div>
                </div>
            </div>
        </Layout>
    )
}