import Layout from "../components/Layout";
import { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import GlobalState from "../GlobalState";

export default function SellWithUsFormSuccess() {
    const { sellWithUsData } = useContext(GlobalState);
    useEffect(() => {
        window.triggerScroll();
    }, []);
    return (
        <Layout>
            <div className="container pb-5 py-5 my-3">
                <div className="sell-with-us-page">
                    <div className="pb-4 pt-5" animate="up">
                        <h1 className="mb-4">{sellWithUsData.sellWithUsSettings.success_title}</h1>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="mb-5" dangerouslySetInnerHTML={{ __html: sellWithUsData.sellWithUsSettings.success_description }} />
                            <Link to="/" className="button backhome-button">{sellWithUsData.sellWithUsSettings.success_button}</Link>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}