import Layout from "../components/Layout";
import { Link, useParams } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import { useState, useContext } from "react";
import GlobalState from "../GlobalState";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../components/Loader";

export default function ArchiveMagazine(props) {
    const { magazineCategories } = useContext(GlobalState);
    let { slug } = useParams();
    const [magazineCategory, setMagazineCategory] = useState([]);
    const [magazineArchive, setMagazineArchive] = useState();
    const [hasMore, setHasMore] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [magazines, setMagazines] = useState();

    const getData = () => {
        console.log("Inside getData()", currentPage);
        axios.post('magazine-category-archive', {
            slug: slug,
            page: currentPage,
        }).then(response => {
            let oldBrands = magazineArchive ? [...magazineArchive] : [];
            setMagazineArchive([...oldBrands, ...response.data.articles.data]);
            setCurrentPage(currentPage + 1);

            setHasMore(response.data.articles.current_page !== response.data.articles.last_page);
            window.triggerScroll();
        });
    }

    useEffect(() => {
        magazineCategories.forEach(magazineCategoryLoop => {
            if (magazineCategoryLoop.slug === slug) {
                setMagazineCategory(magazineCategoryLoop);
            }
            axios.post('magazine-landing').then(response => {
                setMagazines(response.data);

            });
        });
        getData();
    }, [magazineCategories, slug]);

    return (magazineArchive) ? (
        <Layout activePage="magazine" activeMagazineCategoryPage={slug}>
            <div className="container pb-5 pt-lg-5 mt-lg-3">
                <div className="sell-with-us-page">
                    <div className="pb-4 pt-5" animate="up">
                        <h1 className="mb-4">{magazineCategory.title}</h1>
                        <div dangerouslySetInnerHTML={{ __html: magazineCategory.description }} />
                    </div>
                </div>

                <InfiniteScroll
                    dataLength={magazineArchive.length}
                    next={getData}
                    hasMore={hasMore}
                    loader={(
                        <>
                            {
                                hasMore ?
                                    <Loader />
                                    :
                                    null
                            }
                        </>
                    )}
                >

                    <div className="row">
                        {
                            magazineArchive.map(article => (
                                <div className="col-lg-4 col-md-4 col-sm-6 pb-5 d-flex h-100 flex-column" animate="left" key={article.id} >
                                    <Link to={"/single-article/" + article.slug}>
                                        <div className="magazine-section-swiper" >
                                            <div className="ratio ratio-1x1 magazine-swiper">
                                                <img src={article.featured_image_path} alt={article.title} title={article.title} />
                                            </div>
                                            <h6 className="pt-4 ">{article.title}</h6>
                                            <p className="editor-paragraph">{article.excerpt}</p>
                                            <Link to={"/single-article/" + article.slug} className="external-button">{magazines.articles[0]?.read_more_label}</Link>
                                        </div>
                                    </Link>
                                </div>
                            ))
                        }
                    </div>
                </InfiniteScroll>
            </div>
        </Layout>
    )
        :
        < div className="fixed-top h-100 w-100 d-flex align-items-center justify-content-center" >
            <Loader className="loader-home" />
        </div >
}