import Layout from "../components/Layout";
import { Link } from "react-router-dom";
import { useEffect, useContext, useState } from "react";
import GlobalState from "../GlobalState";
import axios from 'axios';
import Loader from "../components/Loader";

export default function HoroscopeMagazine(props) {
    const { horoscopesSettings, isFirstVisit } = useContext(GlobalState);
    const [horoscopes, setHoroscopes] = useState([]);


    useEffect(() => {
        axios.get('horoscopes?first_visit=' + isFirstVisit('horoscopes-landuing', 'true')).then(response => {
            setHoroscopes(response.data);
        });
        window.triggerScroll();
    }, []);

    return (horoscopes) ? (
        <Layout activeHoroscopePage="horoscope" activePage="magazine">
            <div className="container pt-lg-5 mt-lg-3">
                <div className="horoscopes-magazine pb-3 pt-5" animate="up">
                    <div className="row justify-content-center">
                        <h1 className="mb-4">{horoscopesSettings.page_title}</h1>
                        <div className="mb-5 image-inside-text" dangerouslySetInnerHTML={{ __html: horoscopesSettings.page_description }} />
                        {
                            horoscopes.map(horoscope => (
                                <div className="col-lg-4 col-md-4 col-sm-6 col-12 py-5 add-padding" key={horoscope.id}>
                                    <Link to={"/single-horoscope/" + horoscope.slug + "/" + horoscope.latest_article.date}>
                                        <div className="ratio ratio-1x1 horoscope-ratio">
                                            <img src={horoscope.icon} alt={horoscope.name} title={horoscope.name} />
                                        </div>
                                        <h5 className="text-center mt-5">{horoscope.name}</h5>
                                    </Link>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </Layout>
    )
        :
        <div className="fixed-top h-100 w-100 d-flex align-items-center justify-content-center">
            <Loader className="loader-home" />
        </div>
}