import Layout from "../components/Layout";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import axios from 'axios';
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../components/Loader";
import GlobalState from "../GlobalState";

var currentPage = 1;

export default function Category(props) {
    const { addToWishlist, inWishlist, productsCategories, isFirstVisit } = useContext(GlobalState);
    let { categorySlug, subcategorySlug } = useParams();
    const [products, setProducts] = useState();
    const [hasMore, setHasMore] = useState(true);

    const getData = () => {
        axios.get('/category-products/' + categorySlug + (subcategorySlug ? '/' + subcategorySlug : '') + '?page=' + currentPage + '&first_visit=' + (subcategorySlug ? isFirstVisit('subcategories', subcategorySlug) : isFirstVisit('categories', categorySlug))).then(response => {
            setProducts(response.data.products.data);
            let oldProducts = currentPage === 1 ? [] : [...products];
            setProducts([...oldProducts, ...response.data.products.data]);
            currentPage++;

            setHasMore(response.data.products.current_page !== response.data.products.last_page);
            window.triggerScroll();
        });
    }

    useEffect(() => {
        currentPage = 1;
        getData();
    }, [categorySlug, subcategorySlug]);

    return (products) ? (
        <Layout activeCategoryPage={categorySlug} activeSubcategoryPage={subcategorySlug} activePage="shopByCategory">
            <div className="container px-lg-0 pt-lg-5 mt-lg-5">
                <div className="py-4">
                    <div className="d-sm-flex d-block" >
                        {
                            productsCategories.map(category => (

                                category.slug === categorySlug ?
                                    <>
                                        <h1>{category.title} </h1>
                                        {
                                            category.subcategories.map(subcategory => (
                                                subcategory.slug === subcategorySlug ?
                                                    <h1>/{subcategory.title}</h1>
                                                    :
                                                    null
                                            ))
                                        }
                                    </>
                                    :
                                    null

                            ))
                        }
                    </div>
                </div>
                {
                    products.length === 0 ?
                        <div className="search-padding">
                            <div className="text-center">
                                <p>NO RESULTS</p>
                            </div>
                        </div>
                        :
                        <InfiniteScroll
                            dataLength={products.length}
                            next={getData}
                            hasMore={hasMore}
                            loader={(
                                <>
                                    {
                                        hasMore ?
                                            <Loader />
                                            :
                                            null
                                    }
                                </>
                            )}
                        >

                            <div className="single-brand-section">
                                <div className="row  pt-lg-3 ">
                                    {
                                        products.map(product => (
                                            <div className="col-lg-4 col-md-4 col-sm-6 col-12 pb-5 h-auto position-relative single-product" key={product.id}>
                                                <svg className="wishlist-item" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 422.4 375.8" xmlSpace="preserve" onClick={() => addToWishlist(product.slug)}>
                                                    <path className={(inWishlist(product.slug) ? '' : 'wishlist-path active')} d="M211.2,367.8c-5.8,0-11.3-2.1-15.6-5.9c-16.3-14.3-32.1-27.7-46-39.6l-0.1-0.1c-40.8-34.7-75.9-64.7-100.4-94.3  c-27.4-33-40.1-64.3-40.1-98.6c0-33.2,11.4-63.9,32.1-86.3C62,20.5,90.7,8,121.9,8c23.4,0,44.7,7.4,63.6,21.9  c9.5,7.3,18.1,16.3,25.7,26.8c7.6-10.5,16.2-19.5,25.7-26.8C255.7,15.3,277.1,8,300.5,8c31.2,0,60,12.5,80.9,35.2  c20.7,22.4,32.1,53.1,32.1,86.3c0,34.2-12.7,65.5-40.1,98.6c-24.5,29.5-59.7,59.5-100.4,94.3c-13.9,11.9-29.7,25.3-46.1,39.6  C222.5,365.8,217,367.8,211.2,367.8L211.2,367.8z" />
                                                </svg>
                                                <Link to={"/single-product/" + product.slug} >
                                                    <div className="single-product position-relative d-flex h-100 flex-column">
                                                        <div className="ratio single-brand-img">
                                                            <img src={product.featured_image_path} alt={product.name} title={product.name} />
                                                        </div>
                                                        <h6 className="title mb-0 pt-4 pb-2 flex-grow-1 h-auto">{product.name}</h6>
                                                        <p className="mt-4">{product.price}</p>
                                                    </div>
                                                </Link>
                                            </div>
                                        ))

                                    }
                                </div>
                            </div>
                        </InfiniteScroll>
                }
            </div>
        </Layout >
    )
        :
        <div className="fixed-top h-100 w-100 d-flex align-items-center justify-content-center">
            <Loader className="loader-home" />
        </div>
}
