export default function Loader() {
    return (
        <div className="py-5 text-center">

            <div className="loadingio-spinner-spin-555fcsttqvt"><div className="ldio-63jwp8mj8ly">
                <div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div>
            </div></div>

        </div>
    )
}
