import React, { useContext, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Thumbs } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import Layout from "../components/Layout";
import { useEffect } from "react";
import axios from 'axios';
import { useParams } from "react-router-dom";
import Loader from "../components/Loader";
import GlobalState from "../GlobalState";

// install Swiper modules
SwiperCore.use([Navigation, Thumbs]);

export default function SingleProduct(props) {
    let { slug } = useParams();
    const { addToWishlist, inWishlist, isFirstVisit } = useContext(GlobalState);
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [singleProduct, setSingleProduct] = useState();
    const [productsSettings, setProductSettings] = useState([]);

    useEffect(() => {
        axios.get('single-product/' + slug + '?first_visit=' + isFirstVisit('products', slug)).then(response => {
            setSingleProduct(response.data);
            window.triggerScroll();
        });
        axios.post('products-settings').then(response => {
            setProductSettings(response.data[0]);
            window.triggerScroll();
        });
    }, [slug, isFirstVisit]);

    return (singleProduct) ? (
        <Layout>

            <div className="container py-5 mt-3">
                <div className="row align-items-center py-5">
                    <div className="col-lg-5 p-0" animate="left">
                        <Swiper
                            style={{ '--swiper-navigation-color': '#fff', '--swiper-pagination-color': '#fff' }}
                            slidesPerView={1}
                            spaceBetween={5}
                            navigation={{
                                nextEl: '.swiper-button-next',
                                prevEl: '.swiper-button-prev'
                            }}
                            loop={true}
                            thumbs={{ swiper: thumbsSwiper }}
                            className="mySwiper2"
                        >
                            {
                                singleProduct.product.image_gallery_path.map((imageUrl, index) => (
                                    <SwiperSlide key={index}>
                                        <div className="ratio single-product-ratio" >
                                            <img src={imageUrl} alt={singleProduct.product.name} title={singleProduct.product.name} />
                                        </div>
                                    </SwiperSlide>
                                ))
                            }
                            <div className="swiper-button-prev single-product-swiper">
                                <img src="/assets/images/prev-single-product.svg" alt="prev" />
                            </div>
                            <div className="swiper-button-next single-product-swiper">
                                <img src="/assets/images/next-single-product.svg" alt="next" />
                            </div>

                        </Swiper>
                    </div>

                    {singleProduct.product.image_gallery_path.length < 2 ?
                        null :
                        <div className="col-lg-1 pt-lg-0 pt-4">
                            <div className="px-xl-3 px-l-4 px-lg-1 px-md-5 px-sm-5 px-5">
                                <div className="ratio pagination-swiper">
                                    <div animate="left">

                                        <Swiper style={{ height: '100%' }} centeredSlides={true} loop={false} spaceBetween={10} onSwiper={setThumbsSwiper} direction={"horizontal"} slidesPerView={5} watchSlidesProgress={true} className="mySwiper mySwiper-singlepage"
                                            breakpoints={{

                                                991.98: {
                                                    direction: "vertical",
                                                    slidesPerView: 5,
                                                    spaceBetween: 5,
                                                },

                                                // when window width is >= 767.98px
                                                767.98: {
                                                    direction: "horizontal",
                                                    slidesPerView: 5,
                                                    spaceBetween: 20,
                                                },

                                                575.98: {
                                                    direction: "horizontal",
                                                    slidesPerView: 5,
                                                    spaceBetween: 20,
                                                },

                                            }}
                                        >
                                            {
                                                singleProduct.product.image_gallery_path.map((thumbImage, index) => (
                                                    <SwiperSlide className="h-auto" key={index}>
                                                        <div className="ratio pagination-ratio">
                                                            <img src={thumbImage} alt={singleProduct.product.name} title={singleProduct.product.name} />
                                                        </div>
                                                    </SwiperSlide>
                                                ))
                                            }
                                        </Swiper>

                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="col-1"></div>
                    <div className="col-lg-6" animate="right">
                        <div className="single-product-content">
                            <h4 className="mb-4 mt-lg-0 mt-5">{singleProduct.product.name}</h4>
                            <h4>{singleProduct.product.price}</h4>
                            <div className="mt-4 pt-xl-2" dangerouslySetInnerHTML={{ __html: singleProduct?.product.description }} />
                            <div className="d-flex">
                                <div className="flex-grow-1">
                                    <a href={singleProduct?.product.buy_url} target="_blank" rel="noreferrer" className="external-button buy-now">{productsSettings?.buy_now_label}</a>
                                </div>
                                <div className="add-wishlist justify-content-end align-items-center d-flex d-lg-none">
                                    <svg className="wishlist-svg" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 422.4 375.8" xmlSpace="preserve">
                                        <path className="wishlist-path" d="M211.2,367.8c-5.8,0-11.3-2.1-15.6-5.9c-16.3-14.3-32.1-27.7-46-39.6l-0.1-0.1c-40.8-34.7-75.9-64.7-100.4-94.3  c-27.4-33-40.1-64.3-40.1-98.6c0-33.2,11.4-63.9,32.1-86.3C62,20.5,90.7,8,121.9,8c23.4,0,44.7,7.4,63.6,21.9  c9.5,7.3,18.1,16.3,25.7,26.8c7.6-10.5,16.2-19.5,25.7-26.8C255.7,15.3,277.1,8,300.5,8c31.2,0,60,12.5,80.9,35.2  c20.7,22.4,32.1,53.1,32.1,86.3c0,34.2-12.7,65.5-40.1,98.6c-24.5,29.5-59.7,59.5-100.4,94.3c-13.9,11.9-29.7,25.3-46.1,39.6  C222.5,365.8,217,367.8,211.2,367.8L211.2,367.8z" />
                                    </svg>
                                    <p className="mb-0 ms-2">{productsSettings?.add_to_whishlist_label}</p>
                                </div>
                            </div>
                        </div>
                        <div className="add-wishlist align-items-center justify-content-end d-lg-flex d-none" onClick={() => addToWishlist(slug)}>
                            <div className={"wishlist-items align-items-center " + (inWishlist(slug) ? 'active' : '')}>
                                <svg className="wishlist-svg" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 422.4 375.8" xmlSpace="preserve">
                                    <path className="wishlist-path" d="M211.2,367.8c-5.8,0-11.3-2.1-15.6-5.9c-16.3-14.3-32.1-27.7-46-39.6l-0.1-0.1c-40.8-34.7-75.9-64.7-100.4-94.3  c-27.4-33-40.1-64.3-40.1-98.6c0-33.2,11.4-63.9,32.1-86.3C62,20.5,90.7,8,121.9,8c23.4,0,44.7,7.4,63.6,21.9  c9.5,7.3,18.1,16.3,25.7,26.8c7.6-10.5,16.2-19.5,25.7-26.8C255.7,15.3,277.1,8,300.5,8c31.2,0,60,12.5,80.9,35.2  c20.7,22.4,32.1,53.1,32.1,86.3c0,34.2-12.7,65.5-40.1,98.6c-24.5,29.5-59.7,59.5-100.4,94.3c-13.9,11.9-29.7,25.3-46.1,39.6  C222.5,365.8,217,367.8,211.2,367.8L211.2,367.8z" />
                                </svg>
                                <p className="mb-0 ms-3">{productsSettings?.add_to_whishlist_label}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </Layout>
    )
        :
        <div className="fixed-top h-100 w-100 d-flex align-items-center justify-content-center">
            <Loader className="loader-home" />
        </div>
}