import { Link, useParams } from "react-router-dom";
import Layout from "../components/Layout";
import { useEffect, useContext, useState } from "react";
import GlobalState from "../GlobalState";
import axios from 'axios';
import Loader from "../components/Loader";

export default function SingleHoroscope(props) {

    let { slug, date } = useParams();
    const { horoscopesSettings, isFirstVisit } = useContext(GlobalState);
    const [latestSingleHoroscope, setLatestSingleHoroscope] = useState();

    useEffect(() => {
        let dateObj = new Date(date);
        let year = dateObj.getFullYear();
        let month = dateObj.getMonth() + 1;
        let day = dateObj.getDate();

        month = month < 10 ? ("0" + month) : month;
        day = day < 10 ? ("0" + day) : day;

        let dateString = day + '/' + month + '/' + year;

        axios.post('horoscope-filter-by-date?first_visit=' + isFirstVisit('horoscopes-' + slug, date), {
            slug: slug,
            date: dateString,
            page: 1
        }).then(response => {
            setLatestSingleHoroscope(response.data);
            console.log(response.data);
            window.triggerScroll();
        });
    }, [slug, date]);

    return (latestSingleHoroscope) ? (
        <Layout activeHoroscopePage="horoscope" activePage="magazine">

            <div className="container py-5 pt-lg-5 mt-lg-5">
                <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-4 px-4 pb-lg-0 pb-5" animate="left">
                        <div className="ratio ratio-1x1">
                            <img src={latestSingleHoroscope.horoscope?.icon_path} alt={latestSingleHoroscope.title} title={latestSingleHoroscope.title} />
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-8 single-horoscope" animate="right">
                        <h1>{latestSingleHoroscope.title}</h1>
                        <p className="horoscope-date">{latestSingleHoroscope.date}</p>
                        <div dangerouslySetInnerHTML={{ __html: latestSingleHoroscope.content }} />
                        <div className="text-end pt-4">
                            <Link to={"/magazine-horoscope-archive/" + slug} className="external-button">{horoscopesSettings.archive_label}</Link>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
        :
        <div className="fixed-top h-100 w-100 d-flex align-items-center justify-content-center">
            <Loader className="loader-home" />
        </div>
}